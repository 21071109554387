import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyType, GroupingI } from 'common/types';
import {
  EditMemberI, GroupingState, GroupingValues, MenuOpenI,
} from './types';
import getGrouping from './thunk/getGrouping';
import addGroup from './thunk/addGroup';
import deleteGroup from './thunk/deleteGroup';
import renameGroup from './thunk/renameGroup';
import getMembers from './thunk/getMembers';
import addMembers from './thunk/addMembers';
import removeMembers from './thunk/removeMembers';
import updateGroup from './thunk/updateGroup';
import editGroupMember from './thunk/editGroupMember';
import editMemberDemographics from './thunk/editMemberDemographics';
const userLogin: AnyType = JSON.parse(localStorage.getItem('userData') || '{}');
const initialState: GroupingState = {
  breadCrumbs: [],
  zoomedId: userLogin.id?.toString() || null,
  currentDepth: 0,
  currentPath: [],
  nodeMap: {},
  data: {},
  dataBackup: {},
  newGroup: false,
  newFirstGroup: false,
  errorOccured: false,
  currentlyDeleting: '',
  members: [],
  memberMap: {},
  membersToAdd: [],
  membersToRemove: [],
  overlayState: false,
  processing: false,
  nodeMapBackup: {},
  deleteOverlayState: false,
  idForDeletion: null,
  memberOverlayState: false,
  editMemberInfo: null,
  menuOpenState: null,
};

export default createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setZoomedId: (state: GroupingState, action: PayloadAction<string | null | undefined>) : void => {
      state.zoomedId = action.payload;
    },
    setInteractionValues: (state: GroupingState, action: PayloadAction<GroupingValues>) : void => {
      state.zoomedId = action.payload.zoomedId;
      state.currentDepth = action.payload.currentDepth;
      state.currentPath = action.payload.currentPath;
    },
    setBreadCrumbs: (state: GroupingState, action: PayloadAction<AnyType []>) : void => {
      state.breadCrumbs = [...action.payload]
    },
    setNodeMap: (state: GroupingState, action: PayloadAction<object>) : void => {
      state.nodeMap = { ...action.payload }
    },
    setData: (state: GroupingState, action: PayloadAction<GroupingI>) : void => {
      state.data = { ...action.payload }
    },
    setDataBackup: (state: GroupingState, action: PayloadAction<GroupingI>) : void => {
      state.dataBackup = { ...action.payload }
    },
    setNewGroup: (state: GroupingState, action: PayloadAction<boolean>) : void => {
      state.newGroup = action.payload;
    },
    setNewFirstGroup: (state: GroupingState, action: PayloadAction<boolean>) : void => {
      state.newFirstGroup = action.payload;
    },
    setErrorStatus: (state: GroupingState, action: PayloadAction<boolean>) : void => {
      state.errorOccured = action.payload;
    },
    setCurrentlyDeleting: (state: GroupingState, action: PayloadAction<string>) : void => {
      state.currentlyDeleting = action.payload;
    },
    setMembersToAdd: (state: GroupingState, action: PayloadAction<number[]>) : void => {
      state.membersToAdd = action.payload;
    },
    setMembersToRemove: (state: GroupingState, action: PayloadAction<number[]>) : void => {
      state.membersToRemove = action.payload;
    },
    setOverlayState: (state: GroupingState, action: PayloadAction<boolean>) : void => {
      state.overlayState = action.payload;
    },
    setDeleteOverlayState: (state: GroupingState, action: PayloadAction<boolean>) : void => {
      state.deleteOverlayState = action.payload;
    },
    setIdForDeletion: (state: GroupingState, action: PayloadAction<string | null>) : void => {
      state.idForDeletion = action.payload;
    },
    setMemberOverlayState: (state: GroupingState, action: PayloadAction<boolean>) : void => {
      state.memberOverlayState = action.payload;
    },
    setEditMemberInfo: (state: GroupingState, action: PayloadAction<EditMemberI | null>) : void => {
      state.editMemberInfo = action.payload;
    },
    setMenuOpenState: (state: GroupingState, action: PayloadAction<MenuOpenI | null>) : void => {
      state.menuOpenState = action.payload;
    },
  },
  extraReducers: {
    [getGrouping.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [getGrouping.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.data = { ...action.payload.data };
      state.newGroup = action.payload.newGroup;
      state.processing = false;
    },
    [getGrouping.rejected.type]: (state) : void => {
      state.errorOccured = true;
      state.processing = true;
    },
    [getMembers.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [getMembers.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.members = [...action.payload.members];
      state.memberMap = { ...action.payload.memberMap };
      state.processing = false;
    },
    [getMembers.rejected.type]: (state) : void => {
      state.errorOccured = true;
    },
    [addGroup.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [addGroup.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.data = { ...action.payload.data }
      state.nodeMap = { ...action.payload.nodeMap }
      state.newGroup = false;
      state.newFirstGroup = false;
      state.processing = false;
    },
    [addGroup.rejected.type]: (state) : void => {
      state.data = { ...state.dataBackup };
      state.errorOccured = true;
      state.processing = false;
    },
    [deleteGroup.pending.type]: (state) : void => {
      state.dataBackup = { ...state.data };
      state.errorOccured = false;
      state.processing = true;
    },
    [deleteGroup.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.data = { ...action.payload.data };
      state.nodeMap = { ...action.payload.nodeMap };
      state.processing = false;
    },
    [deleteGroup.rejected.type]: (state) : void => {
      state.errorOccured = true;
      state.processing = false;
      state.currentlyDeleting = '';
    },
    [renameGroup.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [renameGroup.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.data = { ...action.payload.data };
      state.nodeMap = { ...action.payload.nodeMap };
      state.processing = false;
    },
    [renameGroup.rejected.type]: (state) : void => {
      state.data = { ...state.dataBackup };
      state.errorOccured = true;
      state.processing = false;
    },
    [updateGroup.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
      state.nodeMapBackup = state.nodeMap;
    },
    [updateGroup.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.nodeMap = { ...action.payload.nodeMap };
      state.processing = false;
    },
    [updateGroup.rejected.type]: (state) : void => {
      state.nodeMap = { ...state.nodeMapBackup };
      state.errorOccured = true;
      state.processing = false;
    },
    [addMembers.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [addMembers.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.nodeMap = { ...action.payload.nodeMap };
      state.data = { ...action.payload.data };
      state.processing = false;
    },
    [addMembers.rejected.type]: (state) : void => {
      state.errorOccured = true;
      state.processing = false;
    },
    [removeMembers.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [removeMembers.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.nodeMap = { ...action.payload.nodeMap };
      state.data = { ...action.payload.data };
      state.processing = false;
    },
    [removeMembers.rejected.type]: (state) : void => {
      state.errorOccured = true;
      state.processing = false;
    },
    [editGroupMember.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [editGroupMember.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.nodeMap = { ...action.payload.nodeMap };
      state.processing = false;
    },
    [editGroupMember.rejected.type]: (state) : void => {
      state.errorOccured = true;
      state.processing = false;
    },
    [editMemberDemographics.pending.type]: (state) : void => {
      state.errorOccured = false;
      state.processing = true;
    },
    [editMemberDemographics.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.memberMap = { ...action.payload.memberMap };
      state.processing = false;
    },
    [editMemberDemographics.rejected.type]: (state) : void => {
      state.errorOccured = true;
      state.processing = false;
    },
  },
});
