import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecoveryData, ResetData, UserData } from '../admin/user/types';
import check from './check/checkThunk';
import login from './login/loginThunk';
import setUserData from './userData/setUserDataThunk';
import setResetData from './userData/setResetDataThunk';
import setRecoveryData from './userData/setRecoveryDataThunk';

export interface SessionState {
  logged: boolean;
  checked: boolean;
  userData: UserData | null;
  resetEmail: string;
  resetToken: string;
  resetDone: boolean;
  invalidEmail: boolean;
  recoveryInitiated: boolean;
}
const userInfo = JSON.parse(localStorage.getItem('userData') || '{}');
const initialState: SessionState = {
  logged: !!localStorage.getItem('sessionUserId'),
  checked: false,
  userData: userInfo,
  resetEmail: '',
  resetToken: '',
  resetDone: false,
  invalidEmail: false,
  recoveryInitiated: false,
};

export default createSlice({
  name: 'session',
  initialState,
  reducers: {
    setLogged: (state: SessionState, action: PayloadAction<boolean>): void => {
      state.logged = action.payload;
    },
  },
  extraReducers: {
    [check.fulfilled.type]: (state): void => {
      state.checked = true;
    },
    [check.rejected.type]: (state): void => {
      state.checked = true;
      state.logged = false;
      state.userData = null;
    },
    [setUserData.fulfilled.type]: (
      state,
      action: PayloadAction<UserData>,
    ): void => {
      state.logged = !!action.payload;
      state.userData = action.payload;
    },
    [login.rejected.type]: (state): void => {
      state.logged = false;
    },
    [setResetData.fulfilled.type]: (
      state,
      action: PayloadAction<ResetData>,
    ) : void => {
      state.resetEmail = action.payload.resetEmail;
      state.resetToken = action.payload.resetToken;
      state.resetDone = action.payload.resetDone;
    },
    [setRecoveryData.fulfilled.type]: (
      state,
      action: PayloadAction<RecoveryData>,
    ) : void => {
      state.invalidEmail = action.payload.invalidEmail;
      state.recoveryInitiated = action.payload.emailSent;
    },
  },
});
