/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { groupingsUrl } from 'definition/api';
import Lang from 'lang/en';
import { RenameGroupFunctionParams } from '../types'
import { renameExistingGroup, updateNodeMapOnRename } from '../utils';
export const renameGroupData = async (
  id: string,
  name: string,
): Promise<boolean> => {
  const response = await apiClient.put(`${groupingsUrl}/${id}`, { name });
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const renameGroup = createAsyncThunk(
  'renameGroup',
  async (data: RenameGroupFunctionParams) => {
    const response = await renameGroupData(data.id, data.name);
    if (response) {
      const newData = renameExistingGroup(data.id, data.name, data.oldGroupData);
      const newNodeMap = updateNodeMapOnRename(data.id, data.name, data.nodeMap);
      return {
        data: newData,
        nodeMap: newNodeMap,
      }
    }
    return {
      data: data.oldGroupData,
      nodeMap: data.nodeMap,
    }
  },
);

export default renameGroup;
