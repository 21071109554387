import React from 'react';
import Typography from 'components/Typography';
const PageNotFound : React.FC = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h1">Page not found : 404 </Typography>
    </div>
  );
};

export default PageNotFound;
