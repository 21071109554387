/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { membersListUrl } from 'definition/api';
import Lang from 'lang/en';
import { Demographic, EditMemberDemographicsFunctionParams, EditMemberDemographicsApiBody } from '../types'
import { updateMemberMaponEditMemberDemographic } from '../utils';
export const editMemberDemographic = async (
  id: number,
  fieldName: Demographic,
  value: string,
) => {
  const params: EditMemberDemographicsApiBody = {};
  params[fieldName] = value;
  const response = await apiClient.put(`${membersListUrl}/${id}`, { ...params });
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const editMemberDemographics = createAsyncThunk(
  'editMemberDemographics',
  async (data: EditMemberDemographicsFunctionParams) => {
    const response = await editMemberDemographic(data.id, data.fieldName, data.value);
    if (response) {
      const updatedValue = response[data.fieldName] || data.value;
      const newMemberMap = updateMemberMaponEditMemberDemographic(data.id, data.fieldName, updatedValue, data.memberMap);
      return {
        memberMap: newMemberMap,
      }
    }
    return {
      memberMap: data.memberMap,
    }
  },
);

export default editMemberDemographics;
