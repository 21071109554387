/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { groupingsUrl } from 'definition/api';
import Lang from 'lang/en';
import { DeleteGroupFunctionParams } from '../types'
import { deleteExistingGroup, updateNodeMapOnDelete } from '../utils';
export const deleteGroupData = async (
  id: string,
): Promise<boolean> => {
  if (parseInt(id)) {
    const response = await apiClient.delete(`${groupingsUrl}/${id}`);
    if (response.status >= 400) {
      throw new Error(response.data.message || Lang.invalidResponse);
    }
    return response.data?.data || {};
  }
  return true;
};

const deleteGroup = createAsyncThunk(
  'deleteGroup',
  async (data: DeleteGroupFunctionParams) => {
    const response = await deleteGroupData(data.id);
    if (response) {
      const newData = deleteExistingGroup(data.parentId, data.id, data.oldGroupData);
      const newNodeMap = updateNodeMapOnDelete(data.id, data.nodeMap);
      return {
        data: newData,
        nodeMap: newNodeMap,
      }
    }
    return {
      data: data.oldGroupData,
      nodeMap: data.nodeMap,
    }
  },
);

export default deleteGroup;
