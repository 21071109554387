// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyType = any;
export interface GroupingI {
  id: number | string;
  name: string;
  value?: number;
  children? : GroupingI [];
  isPlaceholder? :boolean;
}
export interface MemberEntry{
  id: number;
  name: string;
  organisation_id: number;
  member_id: number;
  created_at: string;
  updated_at: string;
  email: string;
  member: MemberEntry;
  start_date?: string | null;
  end_date?: string | null;
  gender: string | null;
  ethnicity: string | null;
  date_of_birth: string | null;
  date_of_joining: string | null;
  responsibility: string | null;
  work_style: string | null;
  parental_status: string | null;
  age: number | null;
  age_group: string | null;
  groups: string[];
  key: number;
}
export interface MinMax{
  min: number;
  max: number;
}
export interface PathMap{
  [key: string]: AnyType
}

export interface Coordinates{
  x: number;
  y: number;
}
export interface MemberMap {
  [key: number]: MemberEntry;
}

export interface SearchResultEntry{
  id: number;
  name: string;
  path: number[];
  depth: number;
}
export enum Timeframe {
  last7Days = 7,
  last30Days = 30,
}
