import axios from 'axios';
import {
  AxiosDispatchFuncType,
  AxiosDispatchType,
  DispatchedActionType,
  ErrorHandlerType,
  RequestHandlerType,
} from './types';
import { networkLog } from '../helpers';
import logoutThunk from '../../session/logout/logoutThunk';
import verifyThunk from '../../session/verify/verifyThunk';
const defaultHeaders = {
  'Content-Type': 'application/json',
};

const unauthorizedHandlerIgnoredUrls: string[] = [];

let axiosDispatch: AxiosDispatchType = null;

const instance = axios.create({
  headers: defaultHeaders,
});
export const setDispatchForAxios: AxiosDispatchFuncType = (dispatch) => {
  axiosDispatch = dispatch;
};

export const logout: DispatchedActionType = () => {
  axiosDispatch && axiosDispatch(logoutThunk());
};

const verifySession: DispatchedActionType = () => {
  axiosDispatch && axiosDispatch(verifyThunk());
};

const handleSpecialStatus = (responseStatus: number): void => {
  switch (responseStatus) {
    case 401:
      logout();
      break;
    case 403:
      verifySession();
      break;
    default:
      break;
  }
};

const getErrorLevel = (status: number) => {
  if (status >= 500) {
    return 'error';
  }
  if (status === 403) {
    return 'warning';
  }
  return 'debug';
};

const handleErrors: ErrorHandlerType = (error) => {
  const {
    request: { responseURL },
  } = error;

  if (
    error.response
    && error.response.status
    && !unauthorizedHandlerIgnoredUrls.some(
      (ignoredUrl) => responseURL.indexOf(ignoredUrl) !== -1,
    )
  ) {
    handleSpecialStatus(error.response.status);
  }

  if (error.response && error.response.status !== 401 && responseURL) {
    const { status, statusText } = error.response;
    const logLevel = getErrorLevel(status);

    networkLog(
      logLevel,
      `Status code: ${status}`,
      statusText,
      responseURL,
      JSON.stringify(error.response.data),
    );
  }

  return error.response || error;
};

const requestHandler: RequestHandlerType = (request) => {
  // TODO : Revisit after confirming if how accessToken will be stored, either 1st level object or inside authUser

  if (request.headers) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }
  return request;
};

instance.interceptors.request.use(requestHandler, handleErrors);
instance.interceptors.response.use((response) => response, handleErrors);

export default instance;
