/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { addRemoveMembersUrl } from 'definition/api';
import Lang from 'lang/en';
import { AddRemoveMembersFunctionParams } from '../types'
import { updateDataWhenMembersAreRemoved, updateNodeMapAfterRemovingMembers } from '../utils';
export const removeMembersData = async (
  params: AddRemoveMembersFunctionParams,
): Promise<boolean> => {
  const data = { group_members: JSON.stringify(params.members) };
  const response = await apiClient.delete(addRemoveMembersUrl.replace('%group_id', params.groupId), { data });
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const removeMembers = createAsyncThunk(
  'removeMembers',
  async (data: AddRemoveMembersFunctionParams) => {
    const response = await removeMembersData(data);
    if (response) {
      const newNodeMap = updateNodeMapAfterRemovingMembers(data.groupId, data.members, data.nodeMap);
      const newData = updateDataWhenMembersAreRemoved(data.groupId, newNodeMap, data.oldGroupData);
      return {
        nodeMap: newNodeMap,
        data: newData,
      }
    } else {
      return {
        nodeMap: data.nodeMap,
        data: data.oldGroupData,
      }
    }
  },
);

export default removeMembers;
