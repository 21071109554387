import React from 'react';
import { colours } from '../../styles/colours';
import styles from './styles.module.scss';

type fontWeightNumber = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 ;
type fontWeightString = 'bold' | 'bolder' | 'inherit' | 'initial' | 'lighter' | 'normal' | 'revert' |'unset';
type variantMapping = {
    [key: string] : React.ElementType
}
type typographyVariants = 'd1' | 'd2' | 'd3' | 'h1' | 'h1Alt' | 'h2' | 'h2Alt' | 'h3' | 'h3Alt' | 'h4' | 'h4Alt' | 'h5' | 's1' | 's2' | 's2Bold' | 'body1' | 'body2' | 'body1XL' | 'body1Bold' | 'body2Bold' | 'body1XLBold' | 'btn' | 'btnM' | 'btnL' | 'caption' | 'captionXS' | 'captionBold' | 'captionXSBold';
type fontWeightType = fontWeightNumber | fontWeightString;
interface indexProps {
children?: React.ReactNode;
variant?: typographyVariants;
className?: string;
color?: string;
fontWeight?: fontWeightType;
style?: object;
}

interface inlineStyles {
  color?: string;
  fontWeight?: fontWeightType;
}

const variantComponentMappings: variantMapping = {
  d1: 'div',
  d2: 'div',
  d3: 'div',
  h1: 'h1',
  h1Alt: 'h1',
  h2: 'h2',
  h2Alt: 'h2',
  h3: 'h3',
  h3Alt: 'h3',
  h4: 'h4',
  h4Alt: 'h4',
  h5: 'h5',
  s1: 'p',
  s2: 'p',
  s2Bold: 'p',
  body1: 'p',
  body2: 'p',
  body1XL: 'p',
  body1Bold: 'p',
  body2Bold: 'p',
  body1XLBold: 'p',
  btn: 'p',
  btnM: 'p',
  btnL: 'p',
  caption: 'p',
  captionXS: 'p',
  captionBold: 'p',
  captionXSBold: 'p',
};

const Typography: React.FC<indexProps> = ({
  children, className = '', variant = 'body1', color = 'black', fontWeight = 400, style = {},
}) => {
  const Component: React.ElementType = variantComponentMappings[variant];
  let styleObj: inlineStyles = {};
  if (color !== 'black') {
    styleObj.color = color;
  }
  if (fontWeight !== 400) {
    styleObj.fontWeight = fontWeight;
  }
  styleObj = { ...styleObj, ...style }
  return (
    <Component className={`${styles[variant]} ${styles.defaultClass} ${className}`} style={styleObj}>
      {children}
    </Component>
  );
};

export default Typography;
