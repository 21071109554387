import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import sessionSlice from 'session/sessionSlice';
import users from 'admin/user/slice';
import dashboardSlice from 'admin/dashboard/slice';
import groupSetupSlice from 'admin/group-setup/slice';
import { setDispatchForAxios } from 'utils/network/apiClient';
const rootReducer: Reducer = combineReducers({
  session: sessionSlice.reducer,
  users: users.reducer,
  dashboard: dashboardSlice.reducer,
  groups: groupSetupSlice.reducer,
});

const createStore = () => configureStore({
  reducer: rootReducer,
});

export const store = createStore();
export const connectStoreToNetwork = (): void => {
  setDispatchForAxios(store.dispatch);
};
export type DispatchType = typeof store.dispatch;

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
