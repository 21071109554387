/* eslint-disable @typescript-eslint/naming-convention */
import mixpanel, { Dict } from 'mixpanel-browser';
const productionHosts = ['prod-aster-frontend.herokuapp.com', 'app.astertheplatform.com'];
const environment = productionHosts.includes(window.location.host) ? 'production' : 'staging';
const { REACT_APP_MIXPANEL_STAGING, REACT_APP_MIXPANEL_PRODUCTION } = process.env;
const token = environment === 'production' ? REACT_APP_MIXPANEL_PRODUCTION : REACT_APP_MIXPANEL_STAGING;
if (token) {
  mixpanel.init(token);
}
const actions = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props: Dict = {}) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
