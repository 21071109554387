interface Lang {
  [key: string]: string;
}

const en: Lang = {
  invalidResponse: 'Invalid response!',
  loginError: 'Your password seems incorrect. ',
  viewByTeams: 'View by teams',
  addMember: 'Add members',
  createGroup: 'Create group',
  rename: 'Rename',
  delete: 'Delete',
  confirm: 'Confirm',
  cancel: 'Cancel',
  yes: 'Yes',
  deletionConfirmation: 'Are you sure you want to delete this group?',
  finishSetup: 'Finish setup',
  dashboardSearchLabel: 'Enter your search terms',
  trendsSearchLabel: 'Find a team to compare',
  noSubgroupsOrMembers: 'This group doesn\'t contain any groups or members.',
  resetPassword: 'Please reset your password',
  passwordMismatch: 'Your passwords don\'t match',
  passwordResetDone: 'Password reset done',
  somethingWentWrong: 'Sorry! Something went wrong.',
  emailError: 'Please enter a valid email address',
  forgotPasswordEmailSent: 'Email for password reset sent successfully!',
  passwordChangedSuccessfully: 'Password changed successfully',
  whatsYourEmail: 'What\'s your email?',
  enterYourPassword: 'Please enter your password.',
  recoverYourPassword: 'Recover your password',
  recoveryInstructionsSent: 'Password recovery instructions sent.',
  checkYourEmail: 'Please check your email.',
  emailNotFound: 'Sorry, we couldn’t find any account associated with that email address.',
  details: 'Details',
  enterNewPassword: 'Please enter your new password',
  confirmNewPassword: 'Please confirm your new password.',
  multipleEthnicGroups: 'Multiple Ethnic Groups',
  mixedRace: 'Mixed Race',
  anonymised: 'anonymised',
  noData: 'no data',
  dataAnonymised: 'data anonymised',
  elevenPlusYears: '11+ years',
};

export default en;
