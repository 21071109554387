/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyType, MinMax, Timeframe } from 'common/types';
import { TrendsMapInfo, TimelineConfig } from 'components/Trends/utils';
import {
  DashboardState, DashboardValues, DemographicsMap, LoadingStates, TrendsType,
} from './types';
import getDashboardInfo from './thunk/getDashboardInfo';
import getTrends from './thunk/getTrends';
import getLastSyncedInfo from './thunk/getLastSyncedInfo';
import getDemographics from './thunk/getDemographics';
const initialState: DashboardState = {
  data: {},
  breadCrumbs: [],
  score: 'No score available',
  zoomedId: '',
  currentDepth: 0,
  currentPath: [],
  minScore: 0,
  maxScore: 0,
  ranges: [],
  nodeMap: {},
  noOfTeams: 0,
  loading: LoadingStates.pending,
  filteredMinScore: 0,
  filteredMaxScore: 0,
  viewTrends: false,
  trendsLoading: LoadingStates.pending,
  trendsMap: {},
  trendsMinScore: 0,
  trendsMaxScore: 0,
  trendsCompareList: [],
  allowTrendsCompare: false,
  timelineConfig: {
    dailyWeekIndex: null,
    keyUsed: null,
    accessor: null,
  },
  yearAndMonthList: [],
  showDateFilter: false,
  lastDataSyncedInfo: LoadingStates.pending,
  timeframeSelected: Timeframe.last7Days,
  trendsType: TrendsType.overall,
  demographicsMap: {},
  demographicsLoading: LoadingStates.pending,
  demographicsHiddenList: [],
};

export default createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setZoomedId: (state, action: PayloadAction<string | null | undefined>) : void => {
      state.zoomedId = action.payload;
    },
    setDashboardValues: (state, action: PayloadAction<DashboardValues>) : void => {
      state.zoomedId = action.payload.zoomedId;
      state.currentDepth = action.payload.currentDepth;
      state.currentPath = action.payload.currentPath;
    },
    setBreadCrumbs: (state, action: PayloadAction<AnyType []>) : void => {
      state.breadCrumbs = [...action.payload]
    },
    setScore: (state, action: PayloadAction<number | string>) : void => {
      state.score = action.payload;
    },
    setMinMax: (state, action: PayloadAction<MinMax>) : void => {
      state.minScore = action.payload.min;
      state.maxScore = action.payload.max;
    },
    setRanges: (state, action: PayloadAction<MinMax []>) : void => {
      state.ranges = [...action.payload];
    },
    setNodeMap: (state, action: PayloadAction<object>) : void => {
      state.nodeMap = { ...action.payload }
    },
    setFilteredScores: (state, action: PayloadAction<MinMax>) : void => {
      state.filteredMinScore = action.payload.min;
      state.filteredMaxScore = action.payload.max;
    },
    setViewTrends: (state, action: PayloadAction<boolean>) : void => {
      state.viewTrends = action.payload;
    },
    setTrendsCompareList: (state, action: PayloadAction<string[]>) : void => {
      state.trendsCompareList = action.payload;
    },
    setAllowTrendsCompare: (state, action: PayloadAction<boolean>) : void => {
      state.allowTrendsCompare = action.payload;
    },
    setTimelineConfig: (state, action: PayloadAction<TimelineConfig>) : void => {
      state.timelineConfig = action.payload;
    },
    setShowCalendar: (state, action: PayloadAction<boolean>) : void => {
      state.showDateFilter = action.payload;
    },
    setTimeframe: (state, action: PayloadAction<Timeframe>) : void => {
      state.timeframeSelected = action.payload;
    },
    setTrendsType: (state, action: PayloadAction<TrendsType>) : void => {
      state.trendsType = action.payload;
    },
    setDemographicsHiddenList: (state, action: PayloadAction<string[]>) : void => {
      state.demographicsHiddenList = action.payload;
    },
  },
  extraReducers: {
    [getDashboardInfo.pending.type]: (state) : void => {
      state.loading = LoadingStates.pending;
    },
    [getDashboardInfo.fulfilled.type]: (state, action: PayloadAction<AnyType>) : void => {
      state.data = action.payload.data;
      state.ranges = action.payload.ranges;
      state.minScore = action.payload.minMax.min;
      state.maxScore = action.payload.minMax.max;
      state.nodeMap = action.payload.nodeMap;
      state.noOfTeams = action.payload.noOfTeams;
      state.loading = LoadingStates.loaded;
      state.filteredMinScore = action.payload.minMax.min;
      state.filteredMaxScore = action.payload.minMax.max;
    },
    [getTrends.pending.type]: (state) : void => {
      state.trendsLoading = LoadingStates.pending;
    },
    [getTrends.fulfilled.type]: (state, action: PayloadAction<TrendsMapInfo>) : void => {
      state.trendsLoading = LoadingStates.loaded;
      state.trendsMap = action.payload.trendsMap;
      state.trendsMinScore = action.payload.minScore;
      state.trendsMaxScore = action.payload.maxScore;
      state.yearAndMonthList = action.payload.yearAndMonthList;
    },
    [getLastSyncedInfo.pending.type]: (state) : void => {
      state.lastDataSyncedInfo = LoadingStates.pending;
    },
    [getLastSyncedInfo.fulfilled.type]: (state, action: PayloadAction<string>) : void => {
      state.lastDataSyncedInfo = action.payload;
    },
    [getLastSyncedInfo.rejected.type]: (state) : void => {
      state.lastDataSyncedInfo = LoadingStates.error;
    },
    [getDemographics.pending.type]: (state) : void => {
      state.demographicsLoading = LoadingStates.pending;
    },
    [getDemographics.fulfilled.type]: (state, action: PayloadAction<DemographicsMap>) : void => {
      state.demographicsLoading = LoadingStates.loaded;
      state.demographicsMap = action.payload;
    },
  },
});
