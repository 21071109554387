/* eslint-disable @typescript-eslint/naming-convention */
import {
  AnyType, GroupingI, PathMap, MemberEntry, MemberMap,
} from 'common/types';

export interface GroupingState {
  breadCrumbs: AnyType [],
  zoomedId: string | null | undefined,
  currentDepth: number,
  currentPath: string[],
  nodeMap: PathMap,
  data: AnyType,
  dataBackup: AnyType,
  newGroup: boolean,
  newFirstGroup: boolean,
  errorOccured: boolean,
  currentlyDeleting: string,
  members: MemberEntry[],
  memberMap: MemberMap,
  membersToAdd: number[],
  membersToRemove: number[],
  overlayState: boolean,
  processing: boolean,
  nodeMapBackup: PathMap,
  deleteOverlayState: boolean,
  idForDeletion: string | null | undefined,
  memberOverlayState: boolean,
  editMemberInfo: EditMemberI | null,
  menuOpenState: MenuOpenI | null,
}

export interface GroupingValues {
  zoomedId: string | null | undefined,
  currentDepth: number,
  currentPath: string[],
  breadCrumbs? :AnyType[],
}
export interface AddGroupResponseType {
  name: string,
  group_id: number | null,
  organisation_id: number,
  updated_at: string,
  created_at: string
  id: number,
}

export interface AddGroupRequestParams {
   name: string,
   parent_id?: number | null;
}
export interface AddGroupFunctionParams {
  id: string;
  name: string,
  parent_id?: number | null;
  oldGroupData: GroupingI;
  nodeMap: PathMap;
  parentId: string;
}
export interface DeleteGroupFunctionParams{
  parentId: string;
  id: string;
  oldGroupData: GroupingI;
  nodeMap: PathMap;
}
export interface RenameGroupFunctionParams{
  id: string;
  name: string;
  oldGroupData: GroupingI;
  nodeMap: PathMap;
}
export interface UpdateGroupFunctionParams{
  id: string;
  type: DateType;
  newDate: string;
  oldGroupData: GroupingI;
  nodeMap: PathMap;
}
export interface AddRemoveMembersFunctionParams{
  groupId: string;
  members: number[];
  nodeMap: PathMap;
  memberMap: MemberMap;
  oldGroupData: GroupingI;
  start_date?: string;
  end_date?: string;
}
export enum DateType {
  startDate = 'startDate',
  endDate = 'endDate'
}
export enum OperationType {
  add= 'add',
  remove= 'remove',
}
export interface EditMemberI{
  groupMemberId: number;
  memberId: number;
  name: string;
  startDate: string | null;
  endDate: string | null;
}
export interface EditMemberFunctionParams{
  id: number;
  body: EditGroupMemberDates;
  oldGroupData: GroupingI;
  nodeMap: PathMap;
  groupId: string;
}
export interface EditGroupMemberDates{
  start_date?: string;
  end_date?: string;
}
export interface MenuOpenI{
  memberId: number,
  name: string,
}
export interface GetMembersFunctionParams{
  groups?: AnyType;
}
export enum Demographic {
  dob = 'date_of_birth',
  gender = 'gender',
  ethnicity = 'ethnicity',
  joiningDate = 'date_of_joining',
}
export interface EditMemberDemographicsFunctionParams{
  id: number;
  fieldName: Demographic;
  value: string;
  memberMap: MemberMap;
}
export interface EditMemberDemographicsApiBody {
  date_of_birth?: string;
  gender?: string;
  ethnicity?: string;
  date_of_joining?: string;
}
export interface IdAndDemographicI {
  id: number | null;
  demographic: Demographic | null;
}
