/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Mixpanel } from 'utils/mixpanel';
import { log } from 'utils/helpers';
import { loginUrl } from '../../definition/api';
import api from '../../utils/network/apiClient';
import { LoginData } from './LoginData';
import setUserData from '../userData/setUserDataThunk';
import setResetData from '../userData/setResetDataThunk';
import Lang from '../../lang/en';
const prepareLoginData = (data: LoginData): FormData => {
  const formData = new FormData();

  formData.append('email', data.email);
  formData.append('password', data.password);

  return formData;
};

const login = createAsyncThunk(
  'login',
  async (data: LoginData, { dispatch }) => {
    const response = await api.post(loginUrl, prepareLoginData(data));
    if (response.status >= 400) {
      throw new Error(Lang.invalidResponse);
    }
    if (response.data && response.data?.message === Lang.resetPassword) {
      await dispatch(setResetData({
        resetEmail: data.email,
        resetToken: response.data?.data,
        resetDone: false,
      }))
      return;
    }
    await dispatch(setUserData(response.data.data));
    try {
      const { id, name, email } = response.data.data;
      const mixpanelProps = {
        $name: name,
        $distinct_id: id.toString(),
        $organisation: name,
        $email: email,
      }
      Mixpanel.identify(id.toString());
      Mixpanel.track('Log In', mixpanelProps);
      Mixpanel.people.set(mixpanelProps);
    } catch (err) {
      log('error', `Mixpanel tracking error: ${err}`);
    }
  },
);

export default login;
