/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { addRemoveMembersUrl } from 'definition/api';
import { log } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';
import Lang from 'lang/en';
import { AnyType } from 'common/types';
import { AddRemoveMembersFunctionParams } from '../types'
import { updateDataWhenMembersAreAdded, updateNodeMapAfterAddingMembers } from '../utils';
export const addMembersData = async (
  params: AddRemoveMembersFunctionParams,
): Promise<AnyType> => {
  const data: AnyType = { members: JSON.stringify(params.members) };
  if (params?.start_date) {
    data.start_date = params.start_date;
  }
  if (params?.end_date) {
    data.end_date = params.end_date;
  }
  const response = await apiClient.post(addRemoveMembersUrl.replace('%group_id', params.groupId), data);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const addMembers = createAsyncThunk(
  'addMembers',
  async (data: AddRemoveMembersFunctionParams) => {
    const response = await addMembersData(data);
    if (response) {
      const newNodeMap = updateNodeMapAfterAddingMembers(data.groupId, data.members, data.memberMap, data.nodeMap, response);
      const newData = updateDataWhenMembersAreAdded(data.groupId, newNodeMap, data.oldGroupData);
      try {
        const userInfo = JSON.parse(localStorage.getItem('userData') || '{}');
        const orgId = userInfo.id || '';
        const groupName = data.nodeMap[data.groupId].name || '';
        const mixpanelProps = {
          $distinct_id: orgId.toString(),
          $name: userInfo.name || '',
          $email: userInfo.email || '',
          $organisation: userInfo.name || '',
          $group_name: groupName,
          $no_of_users_added: data.members.length || 1,
        }
        Mixpanel.identify(orgId.toString());
        Mixpanel.track('Add Member', mixpanelProps);
      } catch (err) {
        log('error', `Mixpanel tracking error: ${err}`);
      }
      return {
        nodeMap: newNodeMap,
        data: newData,
      }
    } else {
      return {
        nodeMap: data.nodeMap,
        data: data.oldGroupData,
      }
    }
  },
);

export default addMembers;
