import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { GroupingI, AnyType } from 'common/types';
import { groupingsUrl } from 'definition/api';
import Lang from 'lang/en';
import { preProcessResponse } from '../utils';

export const getGroupingsData = async (): Promise<AnyType> => {
  const response = await apiClient.get(groupingsUrl);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const getGroupings = createAsyncThunk(
  'groups',
  async () => {
    const response = await getGroupingsData();
    const data = preProcessResponse(response[1]) || {};
    const newGroup = response[1]?.children?.length === 0;
    return { data, newGroup };
  },
);

export default getGroupings;
