/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fetchUserDetails from './thunk/fetchUserDetail';
import { UserI, UserStateI } from '../settings/account/types';

export interface SettingState {
  userDetails: UserI | null;
  loading: boolean;
}

const initialState: SettingState = {
  userDetails: null,
  loading: true,
};

export default createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserDetail: (state, action: PayloadAction<UserI>): void => {
      state.userDetails = action.payload;
    },
  },
  extraReducers: {
    [fetchUserDetails.pending.type]: (state: UserStateI): void => {
      state.loading = true;
    },
    [fetchUserDetails.rejected.type]: (state: UserStateI): void => {
      state.loading = false;
    },
    [fetchUserDetails.fulfilled.type]: (
      state: UserStateI,
      action: PayloadAction<UserI>,
    ): void => {
      state.loading = false;
      state.userDetails = action.payload || {};
    },
  },
});
