export type RoutesType = {
  path: string;
  title: string;
};
export interface RoutesI {
    [key: string]: RoutesType;
}
const routes: RoutesI = {
  login: {
    path: '/login',
    title: 'Login',
  },
  dashboard: {
    path: '/dashboard',
    title: 'Dashboard',
  },
  groupSetup: {
    path: '/group-setup',
    title: 'Group Setup',
  },
  resetPassword: {
    path: '/reset-password',
    title: 'Password Reset',
  },
  memberInfo: {
    path: '/member-info',
    title: 'member Info',
  },
};
export const landingPageUrl = 'https://www.astertheplatform.com/';

export default routes;
