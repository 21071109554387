import PageNotFound from 'pages/web/notFound';
import React, { memo, Suspense } from 'react';
import {
  Route, Routes, BrowserRouter as Router, Navigate,
} from 'react-router-dom';
import routes from './constants';
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ '../pages/web/login'));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "reset_password" */ '../pages/web/reset-password'));
const Dashboard = React.lazy(() => import(/* webpackChunkName: "dashboard" */ '../pages/web/dashboard'));
const GroupSetup = React.lazy(() => import(/* webpackChunkName: "groupsetup" */ '../pages/web/group-setup'));
const MemberInfo = React.lazy(() => import(/* webpackChunkName: "memberInfo" */ '../pages/web/member-info'));

const WebRoutes = () => {
  return (
    <Suspense fallback={<div className="loading" />}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path={routes.login.path} element={<Login />} />
          <Route path={`${routes.resetPassword.path}/:resetToken`} element={<ResetPassword />} />
          <Route path={routes.dashboard.path} element={<Dashboard />} />
          <Route path={routes.groupSetup.path} element={<GroupSetup />} />
          <Route path={routes.memberInfo.path} element={<MemberInfo />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default memo(WebRoutes);
