/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { editMembersUrl } from 'definition/api';
import Lang from 'lang/en';
import { EditMemberFunctionParams, EditGroupMemberDates } from '../types'
import { updateNodemapOnEditMember } from '../utils';
export const editGroupMemberData = async (
  id: number,
  params: EditGroupMemberDates,
) => {
  const response = await apiClient.put(`${editMembersUrl}/${id}`, { ...params });
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const editGroupMember = createAsyncThunk(
  'editGroupMember',
  async (data: EditMemberFunctionParams) => {
    const response = await editGroupMemberData(data.id, data.body);
    if (response) {
      const newNodeMap = updateNodemapOnEditMember(data.id, data.groupId, data.nodeMap, data.body);
      return {
        nodeMap: newNodeMap,
      }
    }
    return {
      nodeMap: data.nodeMap,
    }
  },
);

export default editGroupMember;
