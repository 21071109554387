/* eslint-disable func-names */
import {
  AnyType, MinMax, PathMap, Coordinates,
} from 'common/types';
import { getColourFromScore } from 'utils/helpers';
import { colours } from 'styles/colours';
import traverse from 'traverse';
import { dateInRange } from 'admin/group-setup/utils';

export const getXYCoordinates = (transformValue: string): Coordinates => {
  let x = 0;
  let y = 0;
  if (transformValue.indexOf('translate') !== -1) {
    const valueFormatted = transformValue.slice(12, transformValue.length - 1);
    const coordinatesList = valueFormatted.split(',')
    x = parseInt(coordinatesList[0].slice(0, coordinatesList[0].length - 2));
    y = parseInt(coordinatesList[1].slice(0, coordinatesList[1].length - 2));
  }
  return { x, y };
}
export const refreshPosition = (packingElement: AnyType) => {
  const canvasEl = packingElement?.children[0]?.children[0]?.children[0]?.children[0];
  if (canvasEl && canvasEl.style) {
    canvasEl.style.transform = 'translate3d(0px,0px,0px)';
  }
};

export const triggerScroll = (event: WheelEventInit, packingElement: AnyType) : void => {
  const eventObj = {
    deltaX: event.deltaX?.toFixed(0),
    deltaY: event.deltaY?.toFixed(0),
  }
  const deltaY = typeof eventObj.deltaY === 'string' ? parseInt(eventObj.deltaY) : 0;
  const deltaX = typeof eventObj.deltaX === 'string' ? parseInt(eventObj.deltaX) : 0;
  handleMouseMove(deltaX, deltaY, packingElement, true);
}

export const middleMouseDrag2 = (event: MouseEvent, packingElement: AnyType, startingCoordinates: Coordinates, packingElementCoordinates: Coordinates): void => {
  const canvasEl = packingElement?.children[0]?.children[0]?.children[0]?.children[0];
  if (canvasEl && canvasEl.style) {
    const coordinates: Coordinates = getXYCoordinates(canvasEl.style.transform);
    const deltaX = startingCoordinates.x - event.x + coordinates.x - packingElementCoordinates.x;
    const deltaY = startingCoordinates.y - event.y + coordinates.y - packingElementCoordinates.y;
    handleMouseMove(deltaX, deltaY, packingElement, false);
  }
}

export const handleMouseMove = (deltaX: number, deltaY: number, packingElement: AnyType, withEasing = true) => {
  const canvasEl = packingElement?.children[0]?.children[0]?.children[0]?.children[0];
  if (canvasEl && canvasEl.children.length > 0) {
    const child = canvasEl.children[0]
    const coordinates: Coordinates = getXYCoordinates(canvasEl.style.transform);
    const dimensionReference = Math.min(window.innerHeight, window.innerWidth)
    let maxY = (child.scrollHeight - dimensionReference / 0.85);
    let maxX = (child.scrollWidth - dimensionReference / 0.85);
    maxY = maxY < 0 ? 0 : maxY;
    maxX = maxX < 0 ? 0 : maxX;
    let xPos = coordinates.x;
    let yPos = coordinates.y;
    if (Math.abs(coordinates.x - deltaX) < maxX) {
      xPos = coordinates.x - deltaX;
    } else if (Math.abs(coordinates.x - deltaX) > maxY) {
      xPos = coordinates.x < 0 ? -maxX : maxX;
    }
    if (Math.abs(coordinates.y - deltaY) < maxY) {
      yPos = coordinates.y - deltaY;
    } else if (Math.abs(coordinates.y - deltaY) > maxY) {
      yPos = coordinates.y < 0 ? -maxY : maxY;
    }
    if (withEasing) {
      canvasEl.style.transition = 'all 0s ease';
    }
    canvasEl.style.transform = `translate3d(${xPos}px,${yPos}px,0)`;
  }
};

export const assignValueAndColour = (data: AnyType, ranges: Array<MinMax>, theme: string) => {
  if (data.children.length === 0 && Object.keys(data).includes('score')) {
    const score = data.score !== null ? parseFloat(parseFloat(data.score).toFixed(3)) : null;
    const color = score !== null ? getColourFromScore(score, ranges, theme) : colours.transparent;
    const tooltipColor = score !== null ? getColourFromScore(score, ranges, theme) : colours.transparent;
    return {
      id: data.id,
      name: data.name,
      score,
      value: data.group_members.length || 1,
      color,
      tooltipColor,
      startDate: data?.start_date || null,
      endDate: data?.end_date || null,
    }
  }

  const children: AnyType = [];
  data.children.forEach((child: AnyType) => {
    const startDate = child.start_date ? new Date(child.start_date) : new Date();
    const endDate = child.end_date ? new Date(child.end_date) : null;
    const status = dateInRange(startDate, endDate);
    if (!status) {
      return;
    }
    const childElement: AnyType = assignValueAndColour(child, ranges, theme);
    children.push(childElement);
  });

  let score: AnyType = null;
  if (!Object.keys(data).includes('score')) { // root
    let childrenWithScore = 0;
    data.children.forEach((child: AnyType) => {
      if (child.score !== null) {
        if (score === null) {
          score = 0;
        }
        childrenWithScore += 1;
        score += parseFloat(child.score);
      }
    })
    score = score !== null ? parseFloat((score / childrenWithScore).toFixed(3)) : null;
  } else {
    score = data.score !== null ? parseFloat(parseFloat(data.score).toFixed(3)) : null;
  }
  return {
    id: data.id,
    name: data.name,
    score,
    color: 'white',
    tooltipColor: score !== null ? getColourFromScore(score, ranges, theme) : colours.transparent,
    children: [...children],
    startDate: data?.start_date || null,
    endDate: data?.end_date || null,
  };
};

export const buildPathMap = (data: AnyType, siblings:AnyType[] = [], traversedDepth = 0, currentPath: Array<string> = []): PathMap => {
  const nodeMap:PathMap = {};
  let subNodeMap: PathMap = {};
  if (traversedDepth === 0) {
    nodeMap[data.id] = {
      id: data.id,
      name: data.name,
      value: data.score,
      depth: traversedDepth,
      score: data.score,
      path: [data.id],
      siblings: [],
      startDate: data?.startDate || null,
      endDate: data?.endDate || null,
    }
    if (data.children) {
      data.children.forEach((child: AnyType) => {
        const childMap = buildPathMap(child, data.children, traversedDepth + 1, [data.id]);
        subNodeMap = { ...subNodeMap, ...childMap };
      })
    }
  } else {
    const siblingsOfNode = siblings.filter((child) => (child.id !== data.id))
    const siblingsOfPath: AnyType[] = [];
    siblingsOfNode.forEach((child) => {
      siblingsOfPath.push({
        id: child.id,
        name: child.name,
        value: child.score,
        depth: traversedDepth,
        score: child.score,
        path: [...currentPath, child.id],
      })
    });
    nodeMap[data.id] = {
      id: data.id,
      name: data.name,
      value: data.score,
      depth: traversedDepth,
      score: data.score,
      siblings: [...siblingsOfPath],
      path: [...currentPath, data.id],
      startDate: data?.startDate || null,
      endDate: data?.endDate || null,
    }
    if (data.children) {
      data.children.forEach((child: AnyType) => {
        const childMap = buildPathMap(child, data.children, traversedDepth + 1, [...currentPath, data.id]);
        subNodeMap = { ...subNodeMap, ...childMap };
      })
    }
  }
  return { ...nodeMap, ...subNodeMap };
};

export const calculateNoOfTeams = (data: AnyType): number => {
  let count = 0;
  traverse(data).forEach((node) => {
    if (node?.id && !node.member_id && !node.children) {
      count += 1;
    }
  });
  return count;
}
