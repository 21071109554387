import { AnyType, PathMap } from 'common/types';
import { generateScoreRanges, getColourFromScore } from 'utils/helpers';
import { colours } from 'styles/colours';
import { Serie } from '@nivo/line';
import traverse from 'traverse';
import Lang from 'lang/en';
import { Demographic } from 'admin/group-setup/types';
const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthsFullName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
interface MonthNameToNumberI {
  [key: string]: string;
}
enum WeekType{
  first = 'first',
  last = 'last',
}
export const monthNameToNumber: MonthNameToNumberI = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12',
}
export const shortMonthNameToNumber: MonthNameToNumberI = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}
export enum Views {
  daily= 'daily',
  weekly= 'weekly',
  monthly= 'monthly',
  yearly= 'yearly',
}
export interface TimelineConfig {
  accessor: string | null;
  keyUsed: string | null;
  dailyWeekIndex: string | null;
}
export interface TrendsMapInfo {
  trendsMap: TrendsMap;
  minScore: number;
  maxScore: number;
  yearAndMonthList: string[];
}
export interface ChartData {
  x: string;
  y: number | null;
  description: string;
  color: string;
}
export interface TimeEntry {
  [key: string]: ChartData | TimeframeData;
}
export interface TimeframeData {
  [key: string]: TimeEntry | Views | TimeframeData;
}
export interface TrendsMap{
  [key: string]: TimeframeData;
}
const weekCount = (year: number, monthNumber: number, startDayOfWeek = 1) => {
  const firstDayOfWeek = startDayOfWeek || 0;

  const firstOfMonth = new Date(year, monthNumber - 1, 1);
  const lastOfMonth = new Date(year, monthNumber, 0);
  const numberOfDaysInMonth = lastOfMonth.getDate();
  const firstWeekDay = (firstOfMonth.getDay() - firstDayOfWeek + 7) % 7;

  const used = firstWeekDay + numberOfDaysInMonth;

  return Math.ceil(used / 7);
}
export const createTrendsMap = (data: AnyType, theme = 'earthy'): TrendsMapInfo => {
  const trendsMap : AnyType = {};
  let minScore = 999;
  let maxScore = -999;
  const defaultView = Views.weekly;
  const yearAndMonthSet = new Set<string>();
  /* Daily entries if weekly data isn't there */
  if (!data.weekly) {
    // TODO: handle case wher there is no weekly data
  } else {
    /* Monthly data in weeks and days */
    Object.entries(data.weekly).forEach(([groupId, monthEntries]) => {
      if (!trendsMap[groupId]) {
        trendsMap[groupId] = {};
      }
      trendsMap[groupId].weekly = {};
      trendsMap[groupId].daily = {};
      trendsMap[groupId].defaultView = defaultView;
      const monthEntry: AnyType = monthEntries;
      Object.entries(monthEntry).forEach(([yearAndMonthEntry, weekEntries]) => {
        yearAndMonthSet.add(yearAndMonthEntry);
        trendsMap[groupId].daily[yearAndMonthEntry] = {};
        const [year, month] = yearAndMonthEntry.split('-').map((entry) => parseInt(entry));
        const noOfWeeks = weekCount(year, month);
        const weekEntry: AnyType = weekEntries;
        if (Object.keys(weekEntry).length >= 3) {
          trendsMap[groupId].defaultView = Views.monthly;
        }
        trendsMap[groupId].weekly[yearAndMonthEntry] = {};
        const dateValue = new Date(yearAndMonthEntry);
        let daysInFirstWeek = (8 - dateValue.getDay()) % 7;
        daysInFirstWeek = daysInFirstWeek === 0 ? 7 : daysInFirstWeek;
        const lastOfMonth = new Date(year, month, 0).getDate();
        const monthName = months[month - 1];
        trendsMap[groupId].daily[yearAndMonthEntry].nonEmptyWeek = null;
        for (let i = 0; i < noOfWeeks; i += 1) {
          trendsMap[groupId].daily[yearAndMonthEntry][i] = {};
          const weekAccessor = `week ${i}`;
          let xValue = daysInFirstWeek;
          const dateStart = i === 0 ? 1 : i * 7 - (7 - daysInFirstWeek) + 1;
          let dateEnd = i === 0 ? xValue : dateStart + 6;
          if (i !== 0 && i < noOfWeeks - 1) {
            xValue = i * 7 + daysInFirstWeek;
          } else if (i === noOfWeeks - 1) {
            xValue = i * 7 + daysInFirstWeek;
            xValue = Math.min(xValue, lastOfMonth);
            dateEnd = xValue;
          }
          /* Adding entries for days Week wise */
          let nonNullScores = 0;
          for (let j = dateStart; j <= dateEnd; j += 1) {
            const dateString = `${yearAndMonthEntry}-${j < 10 ? '0' : ''}${j}`
            trendsMap[groupId].daily[yearAndMonthEntry][i][dateString] = {};
            let score = null;
            if (data.daily[groupId] && data.daily[groupId][dateString] && data.daily[groupId][dateString] === 0) {
              score = Lang.anonymised;
            }
            if (data.daily[groupId] && data.daily[groupId][dateString]) {
              score = parseFloat(data.daily[groupId][dateString]);
              minScore = Math.min(minScore, score);
              maxScore = Math.max(maxScore, score);
              nonNullScores += 1;
            }
            const color = colours.filterTrack;
            const dateStandard = new Date(dateString);
            const dayName = days[dateStandard.getDay()];
            trendsMap[groupId].daily[yearAndMonthEntry][i][dateString] = {
              x: dayName,
              y: score,
              description: dateStandard.toDateString().split(' ').slice(1).join(' '),
              color,
            }
          }
          if (nonNullScores > 0) {
            trendsMap[groupId].daily[yearAndMonthEntry].nonEmptyWeek = i;
          }
          const yearMonthDatevalue = `${yearAndMonthEntry}-${xValue}`;
          const dateValueForXValue = new Date(yearMonthDatevalue);
          if (weekEntry[weekAccessor]) {
            const score = parseFloat(weekEntry[weekAccessor]);
            if (score !== null) {
              minScore = Math.min(minScore, score);
              maxScore = Math.max(maxScore, score);
            }
            trendsMap[groupId].weekly[yearAndMonthEntry][i] = {
              x: `${monthName} ${xValue}`,
              y: weekEntry[weekAccessor],
              description: dateValueForXValue.toDateString().split(' ').slice(1).join(' '),
              color: colours.filterTrack,
            }
          } else {
            trendsMap[groupId].weekly[yearAndMonthEntry][i] = {
              x: `${monthName} ${xValue}`,
              y: null,
              description: dateValueForXValue.toDateString().split(' ').slice(1).join(' '),
              color: colours.filterTrack,
            }
          }
          if (weekEntry[weekAccessor] === 0) {
            trendsMap[groupId].weekly[yearAndMonthEntry][i].y = Lang.anonymised;
          }
        }
      })
    })
  }

  /* yearly data in months */
  if (data.monthly) {
    Object.entries(data.monthly).forEach(([groupId, entries]) => {
      if (!trendsMap[groupId]) {
        trendsMap[groupId] = {};
      }
      trendsMap[groupId].monthly = {};
      const yearEntries: AnyType = entries;
      Object.entries(yearEntries).forEach(([yearEntry, monthEntries]) => {
        trendsMap[groupId].monthly[yearEntry] = {};
        const lastTwoDigitsOfYear = yearEntry.slice(2, 4);
        for (let i = 0; i < 12; i += 1) {
          trendsMap[groupId].monthly[yearEntry][i] = {
            x: i !== 0 ? months[i] : `${months[i]} ${lastTwoDigitsOfYear}`,
            y: null,
            description: `${monthsFullName[i]} ${yearEntry}`,
            color: colours.filterTrack,
          }
        }
        const monthEntriesWithScore: AnyType = monthEntries;
        if (Object.keys(monthEntriesWithScore).length >= 3) {
          trendsMap[groupId].defaultView = Views.yearly;
        }
        Object.entries(monthEntriesWithScore).forEach(([monthNo, score]) => {
          const monthIndex = parseInt(monthNo) - 1;
          let monthScore: AnyType = score;
          if (score !== null && score !== 0) {
            monthScore = parseFloat(monthScore);
            minScore = Math.min(minScore, monthScore);
            maxScore = Math.max(maxScore, monthScore);
          }
          if (score === 0) {
            monthScore = Lang.anonymised;
          }
          trendsMap[groupId].monthly[yearEntry][monthIndex].y = monthScore;
        })
      })
    })
  }
  const ranges = generateScoreRanges(minScore, maxScore, 10);
  const dataWithColors = traverse(trendsMap).map(function (node) {
    if (node?.color && node?.y !== null) {
      const newNode = JSON.parse(JSON.stringify(node));
      newNode.color = getColourFromScore(newNode.y, ranges, theme);
      this.update(newNode)
      return newNode;
    }
    return node;
  })
  const yearAndMonthList = Array.from(yearAndMonthSet)
  yearAndMonthList.sort();
  return {
    trendsMap: dataWithColors, minScore, maxScore, yearAndMonthList,
  };
};

export const getLatestTrendsForGroup = (zoomedId: string | number, trendsMap: AnyType, name: string, timelineColours: string[]) => {
  const groupId = zoomedId.toString()
  const data: Serie[] = [];
  let accessor = Views.monthly;
  let keyUsed = new Date().getFullYear().toString();
  let dailyWeekIndex = null;
  if (trendsMap[groupId] && trendsMap[groupId].defaultView) {
    if (trendsMap[groupId].defaultView === Views.yearly) {
      accessor = Views.monthly;
    } else if (trendsMap[groupId].defaultView === Views.monthly) {
      accessor = Views.weekly;
    } else {
      accessor = Views.daily;
    }
    const sortedKeys = Object.keys(trendsMap[groupId][accessor]).sort();
    if (accessor === Views.weekly) {
      if (sortedKeys.length > 0) {
        const latestKey = sortedKeys[sortedKeys.length - 1];
        keyUsed = latestKey;
        let entries: Serie[] = trendsMap[groupId][accessor][latestKey];
        if (sortedKeys.length > 1) {
          const secondLatestKey = sortedKeys[sortedKeys.length - 2];
          const entriesAlt = trendsMap[groupId][accessor][secondLatestKey];
          if (Object.keys(entriesAlt).length > Object.keys(entries).length) {
            entries = [...entriesAlt];
            keyUsed = secondLatestKey;
          }
        }
        const groupData: AnyType = {
          id: zoomedId.toString(),
          data: [],
          color: timelineColours[0],
          name,
        }
        Object.values(entries).forEach((entry) => groupData.data.push(entry));
        data.push(groupData);
      }
    } else if (accessor === Views.daily) {
      if (sortedKeys.length > 0) {
        const latestKey = sortedKeys[sortedKeys.length - 1];
        let entries: Serie[];
        if (trendsMap[groupId][accessor][latestKey].nonEmptyWeek) {
          const index = trendsMap[groupId][accessor][latestKey].nonEmptyWeek;
          entries = trendsMap[groupId][accessor][latestKey][index];
          keyUsed = latestKey;
          dailyWeekIndex = index;
        } else {
          const lastWeeklyEntries = trendsMap[groupId][accessor][latestKey];
          const index = Object.keys(lastWeeklyEntries).length - 2;
          entries = typeof index === 'number' ? trendsMap[groupId][accessor][latestKey][index] : [];
          keyUsed = latestKey;
          dailyWeekIndex = index;
        }
        const groupData: AnyType = {
          id: zoomedId.toString(),
          data: [],
          color: timelineColours[0],
          name,
        }
        Object.values(entries).forEach((entry) => groupData.data.push(entry));
        data.push(groupData);
      }
    } else if (accessor === Views.monthly) {
      if (sortedKeys.length > 0) {
        const latestKey = sortedKeys[sortedKeys.length - 1];
        keyUsed = latestKey;
        const entries: Serie[] = trendsMap[groupId][accessor][latestKey];
        const groupData: AnyType = {
          id: zoomedId.toString(),
          data: [],
          color: timelineColours[0],
          name,
        }
        Object.values(entries).forEach((entry) => groupData.data.push(entry));
        data.push(groupData);
      }
    }
  }
  return {
    data,
    accessor,
    keyUsed,
    dailyWeekIndex,
  }
};

export const getComparisonChartData = (data: Serie[], timelineConfig: TimelineConfig, trendsMap: AnyType, compareList: string[], nodeMap: PathMap, timelineColours: string[]) : Serie[] => {
  const newData = data.filter((entry, index) => {
    if (index === 0 || compareList.includes(entry.id.toString())) {
      return true;
    }
    return false;
  })
  const { accessor, keyUsed, dailyWeekIndex } = timelineConfig;
  const existingKeys: string[] = [];
  const usedColors: string[] = [];

  Object.values(newData).forEach((value) => {
    existingKeys.push(value.id.toString());
    if (value?.color) {
      usedColors.push(value.color);
    }
  })
  const filteredColors = timelineColours.filter((color) => !usedColors.includes(color));
  const filteredCompareList = compareList.filter((groupId) => !existingKeys.includes(groupId));

  if (filteredCompareList.length > 0 && accessor && keyUsed) {
    let entries: AnyType = {};
    for (let i = 0; i < filteredCompareList.length; i += 1) {
      const groupId = filteredCompareList[i];
      if (dailyWeekIndex && trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed] && trendsMap[groupId][accessor][keyUsed][dailyWeekIndex]) {
        entries = trendsMap[groupId][accessor][keyUsed][dailyWeekIndex];
      } else if (trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed]) {
        entries = trendsMap[groupId][accessor][keyUsed];
      }
      const groupData: AnyType = {
        id: nodeMap[groupId].id.toString(),
        data: [],
        color: filteredColors.pop(),
        name: nodeMap[groupId].name,
      }
      Object.values(entries).forEach((entry) => groupData.data.push(entry));
      newData.push(groupData);
    }
  }
  return newData;
};

export const getChartDataOnConfigChange = (zoomedId: string | number, timelineConfig: TimelineConfig, trendsMap: AnyType, compareList: string[], nodeMap: PathMap, timelineColours: string[]) : Serie[] => {
  const newData: Serie[] = [];
  const { accessor, keyUsed, dailyWeekIndex } = timelineConfig;
  const existingKeys: string[] = [];
  const usedColors: string[] = [];
  if (accessor && keyUsed) {
    let entries: AnyType = {};
    if (dailyWeekIndex && trendsMap[zoomedId] && trendsMap[zoomedId][accessor] && trendsMap[zoomedId][accessor][keyUsed] && trendsMap[zoomedId][accessor][keyUsed][dailyWeekIndex]) {
      entries = trendsMap[zoomedId][accessor][keyUsed][dailyWeekIndex];
      if (Object.keys(entries).length > 0 && Object.keys(entries).length !== 7) {
        // Check if 1st week or last
        const datesList = Array.from(Object.keys(entries));
        datesList.sort();
        const firstDateInList = datesList[0];
        const [year, month, date] = firstDateInList.split('-').map((el) => parseInt(el));
        let weekType: WeekType = WeekType.last;
        if (date === 1) {
          weekType = WeekType.first;
        }
        // Case 1: Week is the 1st week of the month
        if (weekType === WeekType.first) {
          // Case 1.1: Month is not the 1st month
          let prevMonthKey = '';
          if (month === 1) {
            const prevYear = year - 1;
            prevMonthKey = `${prevYear}-12`;
          } else { // Case 1.2: Month is the 1st month
            const prevMonth = month - 1;
            prevMonthKey = `${year}-${prevMonth < 10 ? '0' : ''}${prevMonth}`;
          }
          if (trendsMap[zoomedId][accessor][prevMonthKey]) {
            const prevMonthEntries = trendsMap[zoomedId][accessor][prevMonthKey];
            const prevMonthLastDailyIndex = Object.keys(prevMonthEntries).length - 2;
            if (prevMonthLastDailyIndex >= 0) {
              const prevMonthEntry = trendsMap[zoomedId][accessor][prevMonthKey][prevMonthLastDailyIndex];
              entries = { ...prevMonthEntry, ...entries };
            }
          }
        } else { // Week is last week
          let nextMonthKey = '';
          if (month === 12) {
            const nextYear = year + 1;
            nextMonthKey = `${nextYear}-01`;
          } else {
            const nextMonth = month + 1;
            nextMonthKey = `${year}-${nextMonth < 10 ? '0' : ''}${nextMonth}`;
          }
          if (trendsMap[zoomedId][accessor][nextMonthKey]) {
            const nextMonthEntries = trendsMap[zoomedId][accessor][nextMonthKey];
            if (Object.keys(nextMonthEntries).length > 0) {
              const nextMonthEntry = trendsMap[zoomedId][accessor][nextMonthKey][0];
              entries = { ...entries, ...nextMonthEntry };
            }
          }
        }
      }
    } else if (trendsMap[zoomedId] && trendsMap[zoomedId][accessor] && trendsMap[zoomedId][accessor][keyUsed]) {
      entries = trendsMap[zoomedId][accessor][keyUsed];
    }
    const groupData: AnyType = {
      id: nodeMap[zoomedId].id.toString(),
      data: [],
      color: timelineColours[0],
      name: nodeMap[zoomedId].name,
    }
    Object.values(entries).forEach((entry) => groupData.data.push(entry));
    newData.push(groupData);
  }

  Object.values(newData).forEach((value) => {
    existingKeys.push(value.id.toString());
    if (value?.color) {
      usedColors.push(value.color);
    }
  })
  const filteredColors = timelineColours.filter((color) => !usedColors.includes(color));

  if (compareList.length > 0 && accessor && keyUsed) {
    for (let i = 0; i < compareList.length; i += 1) {
      let entries: AnyType = {};
      const groupId = compareList[i];
      if (dailyWeekIndex && trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed] && trendsMap[groupId][accessor][keyUsed][dailyWeekIndex]) {
        entries = trendsMap[groupId][accessor][keyUsed][dailyWeekIndex];
        if (Object.keys(entries).length > 0 && Object.keys(entries).length !== 7) {
          // Check if 1st week or last
          const datesList = Array.from(Object.keys(entries));
          datesList.sort();
          const firstDateInList = datesList[0];
          const [year, month, date] = firstDateInList.split('-').map((el) => parseInt(el));
          let weekType: WeekType = WeekType.last;
          if (date === 1) {
            weekType = WeekType.first;
          }
          // Case 1: Week is the 1st week of the month
          if (weekType === WeekType.first) {
            // Case 1.1: Month is not the 1st month
            let prevMonthKey = '';
            if (month === 1) {
              const prevYear = year - 1;
              prevMonthKey = `${prevYear}-12`;
            } else { // Case 1.2: Month is the 1st month
              const prevMonth = month - 1;
              prevMonthKey = `${year}-${prevMonth < 10 ? '0' : ''}${prevMonth}`;
            }
            if (trendsMap[zoomedId][accessor][prevMonthKey]) {
              const prevMonthEntries = trendsMap[zoomedId][accessor][prevMonthKey];
              const prevMonthLastDailyIndex = Object.keys(prevMonthEntries).length - 2;
              if (prevMonthLastDailyIndex >= 0) {
                const prevMonthEntry = trendsMap[zoomedId][accessor][prevMonthKey][prevMonthLastDailyIndex];
                entries = { ...prevMonthEntry, ...entries };
              }
            }
          } else { // Week is last week
            let nextMonthKey = '';
            if (month === 12) {
              const nextYear = year + 1;
              nextMonthKey = `${nextYear}-01`;
            } else {
              const nextMonth = month + 1;
              nextMonthKey = `${year}-${nextMonth < 10 ? '0' : ''}${nextMonth}`;
            }
            if (trendsMap[zoomedId][accessor][nextMonthKey]) {
              const nextMonthEntries = trendsMap[zoomedId][accessor][nextMonthKey];
              if (Object.keys(nextMonthEntries).length > 0) {
                const nextMonthEntry = trendsMap[zoomedId][accessor][nextMonthKey][0];
                entries = { ...entries, ...nextMonthEntry };
              }
            }
          }
        }
      } else if (trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed]) {
        entries = trendsMap[groupId][accessor][keyUsed];
      }
      const groupData: AnyType = {
        id: nodeMap[groupId].id.toString(),
        data: [],
        color: filteredColors.pop(),
        name: nodeMap[groupId].name,
      }
      Object.values(entries).forEach((entry) => groupData.data.push(entry));
      newData.push(groupData);
    }
  }
  return newData;
};
export const getPrevAndNextInfo = (timelineConfig: TimelineConfig, zoomedId: string, trendsMap: TrendsMap) => {
  let allowPrev = false;
  let allowNext = false;
  let newPrevKey = '';
  let newNextKey = '';
  let newPrevDailyIndex = 0;
  let newNextDailyIndex = 0;
  if (timelineConfig.accessor && timelineConfig.keyUsed && zoomedId !== null && zoomedId !== undefined) {
    if (timelineConfig.accessor === Views.daily && timelineConfig.dailyWeekIndex !== null && trendsMap[zoomedId]?.daily) {
      const updatedTrendsMap: AnyType = trendsMap;
      const dailyTrends = updatedTrendsMap[zoomedId].daily[timelineConfig.keyUsed];
      const weekLength = (dailyTrends && Object.keys(dailyTrends) && Object.keys(dailyTrends).length > 0) ? Object.keys(dailyTrends).length - 1 : 0;
      const currentIndex = parseInt(timelineConfig.dailyWeekIndex);
      // Case 1: Mid week
      if (currentIndex > 0 && currentIndex < weekLength - 1) {
        newPrevKey = timelineConfig.keyUsed;
        newNextKey = timelineConfig.keyUsed;
        newPrevDailyIndex = currentIndex - 1;
        newNextDailyIndex = currentIndex + 1;
        allowPrev = true;
        allowNext = true;
      } else if (currentIndex === 0) { // Case 2: 1st Week
        newNextKey = timelineConfig.keyUsed;
        newNextDailyIndex = currentIndex + 1;
        allowNext = true;
        const [year, month] = timelineConfig.keyUsed.split('-');
        const currentYear = parseInt(year);
        const currentMonth = parseInt(month);
        // case 2.1: Current month is not 1st month
        if (currentMonth > 1) {
          const prevMonthNumber = currentMonth - 1;
          newPrevKey = `${year}-${prevMonthNumber < 10 ? 0 : ''}${prevMonthNumber}`;
        } else { // Case 2.2 : 1st Month
          const prevYear = currentYear - 1;
          newPrevKey = `${prevYear}-12`;
        }
        if (Object.keys(updatedTrendsMap[zoomedId].daily).includes(newPrevKey)) {
          allowPrev = true;
          newPrevDailyIndex = Object.keys(updatedTrendsMap[zoomedId].daily[newPrevKey]).length - 2;
        }
      } else if (currentIndex === weekLength - 1) { // Case 3: Last week
        newPrevKey = timelineConfig.keyUsed;
        newPrevDailyIndex = currentIndex - 1;
        allowPrev = true;
        const [year, month] = timelineConfig.keyUsed.split('-');
        const currentYear = parseInt(year);
        const currentMonth = parseInt(month);
        if (currentMonth < 12) { // Case 3.1 Current month is not 12th month
          const nextMonthNumber = currentMonth + 1;
          newNextKey = `${year}-${nextMonthNumber < 10 ? 0 : ''}${nextMonthNumber}`;
        } else {
          const nextYear = currentYear + 1;
          newNextKey = `${nextYear}-01`;
        }
        if (Object.keys(updatedTrendsMap[zoomedId].daily).includes(newNextKey)) {
          allowNext = true;
          newNextDailyIndex = 0;
        }
      }
    } else if (timelineConfig.accessor === Views.weekly && trendsMap[zoomedId]?.weekly) {
      const weeklyTrends = trendsMap[zoomedId].weekly;
      const [year, month] = timelineConfig.keyUsed.split('-');
      const monthNumber = parseInt(month);
      if (monthNumber > 1 && monthNumber < 12) {
        const nextMonthNumber = monthNumber + 1;
        const prevMonthNumber = monthNumber - 1;
        newPrevKey = `${year}-${prevMonthNumber < 10 ? 0 : ''}${prevMonthNumber}`;
        newNextKey = `${year}-${nextMonthNumber < 10 ? 0 : ''}${nextMonthNumber}`;
      } else if (monthNumber === 1) {
        const prevYear = parseInt(year) - 1;
        newPrevKey = `${prevYear}-12`;
        newNextKey = `${year}-02`;
      } else if (monthNumber === 12) {
        const nextYear = parseInt(year) + 1;
        newPrevKey = `${year}-11`;
        newNextKey = `${nextYear}-01`;
      }
      if (Object.keys(weeklyTrends).includes(newPrevKey)) {
        allowPrev = true;
      }
      if (Object.keys(weeklyTrends).includes(newNextKey)) {
        allowNext = true;
      }
    } else if (timelineConfig.accessor === Views.monthly && trendsMap[zoomedId] && trendsMap[zoomedId]?.monthly) {
      const monthlyTrends = trendsMap[zoomedId].monthly;
      const year = parseInt(timelineConfig.keyUsed);
      const prevYear = year - 1;
      const nextyear = year + 1;
      newPrevKey = `${prevYear}`;
      newNextKey = `${nextyear}`;
      if (Object.keys(monthlyTrends).includes(newPrevKey)) {
        allowPrev = true;
      }
      if (Object.keys(monthlyTrends).includes(newNextKey)) {
        allowNext = true;
      }
    }
  }
  return {
    allowPrev,
    allowNext,
    newPrevKey,
    newNextKey,
    newPrevDailyIndex,
    newNextDailyIndex,
  }
}
export const createGroupDemographicsMap = (data: AnyType, theme = 'earthy') => {
  const demographicsMap :AnyType = {}
  Object.entries(data).forEach(([demographic, demographicData]) => { // every demographic
    const tempMap: AnyType = {};
    const defaultView = Views.weekly;
    let minScore = 999;
    let maxScore = -999;
    const demographicEntry: AnyType = demographicData;
    const yearAndMonthSet = new Set<string>();
    if (Object.keys(demographicEntry).length > 0) {
      /* Monthly data in weeks and days */
      Object.entries(demographicEntry?.weekly).forEach(([subCategory, monthEntries]) => {
        if (!tempMap[subCategory]) {
          tempMap[subCategory] = {};
        }
        tempMap[subCategory].weekly = {};
        tempMap[subCategory].daily = {};
        tempMap[subCategory].defaultView = defaultView;
        const monthEntry: AnyType = monthEntries;
        Object.entries(monthEntry).forEach(([yearAndMonthEntry, weekEntries]) => {
          yearAndMonthSet.add(yearAndMonthEntry);
          tempMap[subCategory].daily[yearAndMonthEntry] = {};
          const [year, month] = yearAndMonthEntry.split('-').map((entry) => parseInt(entry));
          const noOfWeeks = weekCount(year, month);
          const weekEntry: AnyType = weekEntries;
          if (Object.keys(weekEntry).length >= 3) {
            tempMap[subCategory].defaultView = Views.monthly;
          }
          tempMap[subCategory].weekly[yearAndMonthEntry] = {};
          const dateValue = new Date(yearAndMonthEntry);
          let daysInFirstWeek = (8 - dateValue.getDay()) % 7;
          daysInFirstWeek = daysInFirstWeek === 0 ? 7 : daysInFirstWeek;
          const lastOfMonth = new Date(year, month, 0).getDate();
          const monthName = months[month - 1];
          tempMap[subCategory].daily[yearAndMonthEntry].nonEmptyWeek = null;
          for (let i = 0; i < noOfWeeks; i += 1) {
            tempMap[subCategory].daily[yearAndMonthEntry][i] = {};
            const weekAccessor = `week ${i}`;
            let xValue = daysInFirstWeek;
            const dateStart = i === 0 ? 1 : i * 7 - (7 - daysInFirstWeek) + 1;
            let dateEnd = i === 0 ? xValue : dateStart + 6;
            if (i !== 0 && i < noOfWeeks - 1) {
              xValue = i * 7 + daysInFirstWeek;
            } else if (i === noOfWeeks - 1) {
              xValue = i * 7 + daysInFirstWeek;
              xValue = Math.min(xValue, lastOfMonth);
              dateEnd = xValue;
            }
            /* Adding entries for days Week wise */
            let nonNullScores = 0;
            for (let j = dateStart; j <= dateEnd; j += 1) {
              const dateString = `${yearAndMonthEntry}-${j < 10 ? '0' : ''}${j}`
              tempMap[subCategory].daily[yearAndMonthEntry][i][dateString] = {};
              let score = null;
              if (demographicEntry?.daily && demographicEntry.daily[subCategory] && demographicEntry.daily[subCategory][dateString] === 0) {
                score = Lang.anonymised;
              }
              if (demographicEntry?.daily && demographicEntry.daily[subCategory] && demographicEntry.daily[subCategory][dateString]) {
                score = parseFloat(demographicEntry.daily[subCategory][dateString]);
                minScore = Math.min(minScore, score);
                maxScore = Math.max(maxScore, score);
                nonNullScores += 1;
              }
              const color = colours.filterTrack;
              const dateStandard = new Date(dateString);
              const dayName = days[dateStandard.getDay()];
              tempMap[subCategory].daily[yearAndMonthEntry][i][dateString] = {
                x: dayName,
                y: score,
                description: dateStandard.toDateString().split(' ').slice(1).join(' '),
                color,
              }
            }
            if (nonNullScores > 0) {
              tempMap[subCategory].daily[yearAndMonthEntry].nonEmptyWeek = i;
            }
            const yearMonthDatevalue = `${yearAndMonthEntry}-${xValue}`;
            const dateValueForXValue = new Date(yearMonthDatevalue);
            if (weekEntry[weekAccessor]) {
              const score = parseFloat(weekEntry[weekAccessor]);
              if (score !== null) {
                minScore = Math.min(minScore, score);
                maxScore = Math.max(maxScore, score);
              }
              tempMap[subCategory].weekly[yearAndMonthEntry][i] = {
                x: `${monthName} ${xValue}`,
                y: weekEntry[weekAccessor],
                description: dateValueForXValue.toDateString().split(' ').slice(1).join(' '),
                color: colours.filterTrack,
              }
            } else {
              tempMap[subCategory].weekly[yearAndMonthEntry][i] = {
                x: `${monthName} ${xValue}`,
                y: null,
                description: dateValueForXValue.toDateString().split(' ').slice(1).join(' '),
                color: colours.filterTrack,
              }
            }
            if (weekEntry[weekAccessor] === 0) {
              tempMap[subCategory].weekly[yearAndMonthEntry][i].y = Lang.anonymised;
            }
          }
        })
      })
      /* yearly data in months */
      if (demographicEntry?.monthly) {
        Object.entries(demographicEntry.monthly).forEach(([subCategory, entries]) => {
          if (!tempMap[subCategory]) {
            tempMap[subCategory] = {};
          }
          tempMap[subCategory].monthly = {};
          const yearEntries: AnyType = entries;
          Object.entries(yearEntries).forEach(([yearEntry, monthEntries]) => {
            tempMap[subCategory].monthly[yearEntry] = {};
            const lastTwoDigitsOfYear = yearEntry.slice(2, 4);
            for (let i = 0; i < 12; i += 1) {
              tempMap[subCategory].monthly[yearEntry][i] = {
                x: i !== 0 ? months[i] : `${months[i]} ${lastTwoDigitsOfYear}`,
                y: null,
                description: `${monthsFullName[i]} ${yearEntry}`,
                color: colours.filterTrack,
              }
            }
            const monthEntriesWithScore: AnyType = monthEntries;
            if (Object.keys(monthEntriesWithScore).length >= 3) {
              tempMap[subCategory].defaultView = Views.yearly;
            }
            Object.entries(monthEntriesWithScore).forEach(([monthNo, score]) => {
              const monthIndex = parseInt(monthNo) - 1;
              let monthScore: AnyType = score;
              if (score !== null && score !== 0) {
                monthScore = parseFloat(monthScore);
                minScore = Math.min(minScore, monthScore);
                maxScore = Math.max(maxScore, monthScore);
              }
              if (score === 0) {
                monthScore = Lang.anonymised;
              }
              tempMap[subCategory].monthly[yearEntry][monthIndex].y = monthScore;
            })
          })
        })
      }
    }
    const ranges = generateScoreRanges(minScore, maxScore, 10);
    const dataWithColors = traverse(tempMap).map(function (node) {
      if (node?.color && node?.y !== null) {
        const newNode = JSON.parse(JSON.stringify(node));
        newNode.color = getColourFromScore(newNode.y, ranges, theme);
        this.update(newNode)
        return newNode;
      }
      return node;
    })
    const yearAndMonthList = Array.from(yearAndMonthSet)
    yearAndMonthList.sort();
    demographicsMap[demographic] = {
      trendsMap: dataWithColors, minScore, maxScore, yearAndMonthList,
    };
  })
  return demographicsMap;
}

export const getComparisonChartDataForDemographics = (data: Serie[], timelineConfig: TimelineConfig, trendsMap: AnyType, compareList: string[], timelineColours: string[]) : Serie[] => {
  const newData = data.filter((entry, index) => {
    if (index === 0 || compareList.includes(entry.id.toString())) {
      return true;
    }
    return false;
  })
  const { accessor, keyUsed, dailyWeekIndex } = timelineConfig;
  const existingKeys: string[] = [];
  const usedColors: string[] = [];

  Object.values(newData).forEach((value) => {
    existingKeys.push(value.id.toString());
    if (value?.color) {
      usedColors.push(value.color);
    }
  })
  const filteredColors = timelineColours.filter((color) => !usedColors.includes(color));
  const filteredCompareList = compareList.filter((groupId) => !existingKeys.includes(groupId));

  if (filteredCompareList.length > 0 && accessor && keyUsed) {
    let entries: AnyType = {};
    for (let i = 0; i < filteredCompareList.length; i += 1) {
      const groupId = filteredCompareList[i];
      if (dailyWeekIndex && trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed] && trendsMap[groupId][accessor][keyUsed][dailyWeekIndex]) {
        entries = trendsMap[groupId][accessor][keyUsed][dailyWeekIndex];
      } else if (trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed]) {
        entries = trendsMap[groupId][accessor][keyUsed];
      }
      const groupData: AnyType = {
        id: groupId,
        data: [],
        color: filteredColors.pop(),
        name: groupId,
      }
      Object.values(entries).forEach((entry) => groupData.data.push(entry));
      newData.push(groupData);
    }
  }
  return newData;
};
export const getChartDataOnConfigChangeForDemographics = (zoomedId: string | number, timelineConfig: TimelineConfig, trendsMap: AnyType, compareList: string[], timelineColours: string[]) : Serie[] => {
  const newData: Serie[] = [];
  const { accessor, keyUsed, dailyWeekIndex } = timelineConfig;
  const existingKeys: string[] = [];
  const usedColors: string[] = [];
  if (accessor && keyUsed) {
    let entries: AnyType = {};
    if (dailyWeekIndex && trendsMap[zoomedId] && trendsMap[zoomedId][accessor] && trendsMap[zoomedId][accessor][keyUsed] && trendsMap[zoomedId][accessor][keyUsed][dailyWeekIndex]) {
      entries = trendsMap[zoomedId][accessor][keyUsed][dailyWeekIndex];
      if (Object.keys(entries).length > 0 && Object.keys(entries).length !== 7) {
        // Check if 1st week or last
        const datesList = Array.from(Object.keys(entries));
        datesList.sort();
        const firstDateInList = datesList[0];
        const [year, month, date] = firstDateInList.split('-').map((el) => parseInt(el));
        let weekType: WeekType = WeekType.last;
        if (date === 1) {
          weekType = WeekType.first;
        }
        // Case 1: Week is the 1st week of the month
        if (weekType === WeekType.first) {
          // Case 1.1: Month is not the 1st month
          let prevMonthKey = '';
          if (month === 1) {
            const prevYear = year - 1;
            prevMonthKey = `${prevYear}-12`;
          } else { // Case 1.2: Month is the 1st month
            const prevMonth = month - 1;
            prevMonthKey = `${year}-${prevMonth < 10 ? '0' : ''}${prevMonth}`;
          }
          if (trendsMap[zoomedId][accessor][prevMonthKey]) {
            const prevMonthEntries = trendsMap[zoomedId][accessor][prevMonthKey];
            const prevMonthLastDailyIndex = Object.keys(prevMonthEntries).length - 2;
            if (prevMonthLastDailyIndex >= 0) {
              const prevMonthEntry = trendsMap[zoomedId][accessor][prevMonthKey][prevMonthLastDailyIndex];
              entries = { ...prevMonthEntry, ...entries };
            }
          }
        } else { // Week is last week
          let nextMonthKey = '';
          if (month === 12) {
            const nextYear = year + 1;
            nextMonthKey = `${nextYear}-01`;
          } else {
            const nextMonth = month + 1;
            nextMonthKey = `${year}-${nextMonth < 10 ? '0' : ''}${nextMonth}`;
          }
          if (trendsMap[zoomedId][accessor][nextMonthKey]) {
            const nextMonthEntries = trendsMap[zoomedId][accessor][nextMonthKey];
            if (Object.keys(nextMonthEntries).length > 0) {
              const nextMonthEntry = trendsMap[zoomedId][accessor][nextMonthKey][0];
              entries = { ...entries, ...nextMonthEntry };
            }
          }
        }
      }
    } else if (trendsMap[zoomedId] && trendsMap[zoomedId][accessor] && trendsMap[zoomedId][accessor][keyUsed]) {
      entries = trendsMap[zoomedId][accessor][keyUsed];
    }
    const groupData: AnyType = {
      id: zoomedId,
      data: [],
      color: timelineColours[0],
      name: zoomedId,
    }
    Object.values(entries).forEach((entry) => groupData.data.push(entry));
    newData.push(groupData);
  }

  Object.values(newData).forEach((value) => {
    existingKeys.push(value.id.toString());
    if (value?.color) {
      usedColors.push(value.color);
    }
  })
  const filteredColors = timelineColours.filter((color) => !usedColors.includes(color));

  if (compareList.length > 0 && accessor && keyUsed) {
    for (let i = 0; i < compareList.length; i += 1) {
      let entries: AnyType = {};
      const groupId = compareList[i];
      if (dailyWeekIndex && trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed] && trendsMap[groupId][accessor][keyUsed][dailyWeekIndex]) {
        entries = trendsMap[groupId][accessor][keyUsed][dailyWeekIndex];
        if (Object.keys(entries).length > 0 && Object.keys(entries).length !== 7) {
          // Check if 1st week or last
          const datesList = Array.from(Object.keys(entries));
          datesList.sort();
          const firstDateInList = datesList[0];
          const [year, month, date] = firstDateInList.split('-').map((el) => parseInt(el));
          let weekType: WeekType = WeekType.last;
          if (date === 1) {
            weekType = WeekType.first;
          }
          // Case 1: Week is the 1st week of the month
          if (weekType === WeekType.first) {
            // Case 1.1: Month is not the 1st month
            let prevMonthKey = '';
            if (month === 1) {
              const prevYear = year - 1;
              prevMonthKey = `${prevYear}-12`;
            } else { // Case 1.2: Month is the 1st month
              const prevMonth = month - 1;
              prevMonthKey = `${year}-${prevMonth < 10 ? '0' : ''}${prevMonth}`;
            }
            if (trendsMap[zoomedId][accessor][prevMonthKey]) {
              const prevMonthEntries = trendsMap[zoomedId][accessor][prevMonthKey];
              const prevMonthLastDailyIndex = Object.keys(prevMonthEntries).length - 2;
              if (prevMonthLastDailyIndex >= 0) {
                const prevMonthEntry = trendsMap[zoomedId][accessor][prevMonthKey][prevMonthLastDailyIndex];
                entries = { ...prevMonthEntry, ...entries };
              }
            }
          } else { // Week is last week
            let nextMonthKey = '';
            if (month === 12) {
              const nextYear = year + 1;
              nextMonthKey = `${nextYear}-01`;
            } else {
              const nextMonth = month + 1;
              nextMonthKey = `${year}-${nextMonth < 10 ? '0' : ''}${nextMonth}`;
            }
            if (trendsMap[zoomedId][accessor][nextMonthKey]) {
              const nextMonthEntries = trendsMap[zoomedId][accessor][nextMonthKey];
              if (Object.keys(nextMonthEntries).length > 0) {
                const nextMonthEntry = trendsMap[zoomedId][accessor][nextMonthKey][0];
                entries = { ...entries, ...nextMonthEntry };
              }
            }
          }
        }
      } else if (trendsMap[groupId] && trendsMap[groupId][accessor] && trendsMap[groupId][accessor][keyUsed]) {
        entries = trendsMap[groupId][accessor][keyUsed];
      }
      const groupData: AnyType = {
        id: groupId,
        data: [],
        color: filteredColors.pop(),
        name: groupId,
      }
      Object.values(entries).forEach((entry) => groupData.data.push(entry));
      newData.push(groupData);
    }
  }
  return newData;
};
