/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { trendsUrl, organisationTrendsUrl } from 'definition/api';
import Lang from 'lang/en';
import { AnyType } from 'common/types';
import { createTrendsMap } from 'components/Trends/utils';
import { GetTrendsFunctionParams } from '../types';
export const getTrendsInfo = async (): Promise<AnyType> => {
  const response = await apiClient.get(trendsUrl);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};
export const getOrgTrendsInfo = async (): Promise<AnyType> => {
  const response = await apiClient.get(organisationTrendsUrl);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};
const getTrends = createAsyncThunk(
  'getTrends',
  async (data: GetTrendsFunctionParams) => {
    const userInfo = JSON.parse(localStorage.getItem('userData') || '{}');
    const response = await getTrendsInfo();
    const orgResponse = await getOrgTrendsInfo();
    const combinedResponse = response;
    if (userInfo?.id) {
      if (combinedResponse.daily && orgResponse.daily) {
        combinedResponse.daily[userInfo.id] = { ...orgResponse.daily };
      }
      if (combinedResponse.weekly && orgResponse.weekly) {
        combinedResponse.weekly[userInfo.id] = { ...orgResponse.weekly };
      }
      if (combinedResponse.monthly && orgResponse.monthly) {
        combinedResponse.monthly[userInfo.id] = { ...orgResponse.monthly };
      }
    }
    return createTrendsMap(combinedResponse, data.theme);
  },
);

export default getTrends;
