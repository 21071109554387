import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { lastDataSyncedInfoUrl } from 'definition/api';
import Lang from 'lang/en';
import { AnyType } from 'common/types';

export const getLastSyncedInfoData = async (): Promise<AnyType> => {
  const response = await apiClient.get(lastDataSyncedInfoUrl);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};
const getLastSyncedInfo = createAsyncThunk(
  'getLastSyncedInfo',
  async () => {
    const response = await getLastSyncedInfoData();
    return response;
  },
);

export default getLastSyncedInfo;
