import { createAsyncThunk } from '@reduxjs/toolkit';
import { RecoveryData } from '../../admin/user/types';

const setRecoveryData = createAsyncThunk(
  'setRecoveryData',
  async (data: RecoveryData | null, { dispatch }) => {
    return data;
  },
);

export default setRecoveryData;
