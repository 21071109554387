import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { AnyType, Timeframe } from 'common/types';
import { groupingsUrl } from 'definition/api';
import Lang from 'lang/en';
import { generateScoreRanges } from 'utils/helpers';
import { assignValueAndColour, buildPathMap, calculateNoOfTeams } from 'components/CirclePacking/utils';

export const getDashboardInfoData = async (timeframe: Timeframe): Promise<AnyType> => {
  let url = groupingsUrl;
  if (timeframe !== Timeframe.last7Days) {
    url += `?last=${timeframe}`;
  }
  const response = await apiClient.get(url);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const getDashboardInfo = createAsyncThunk(
  'dashboardInfo',
  async (timeframe: Timeframe) => {
    const response = await getDashboardInfoData(timeframe);
    const minMaxData = response[0];
    const data = response[1];
    const minScore: number = parseFloat(minMaxData?.min_score) || 0;
    const maxScore: number = parseFloat(minMaxData?.max_score) || 0;
    const ranges = generateScoreRanges(minScore, maxScore, 10);
    const newData = assignValueAndColour(data, ranges, 'earthy');
    const nodeMap = buildPathMap(newData, [], 0, []);
    const noOfTeams = calculateNoOfTeams(newData);
    return {
      data: newData,
      minMax: {
        min: parseFloat(minScore.toFixed(3)), max: parseFloat(maxScore.toFixed(3)),
      },
      ranges,
      nodeMap,
      noOfTeams,
    };
  },
);

export default getDashboardInfo;
