import { colours, sentiments } from 'styles/colours';
import { AnyType, MinMax } from '../common/types';
type LogLevel = 'critical' | 'error' | 'warning' | 'debug' | 'info';
const getConsoleLogger = (level: LogLevel): ((...args: string[]) => void) => {
  switch (level) {
    case 'critical':
    case 'error':
      return console.error;
    case 'warning':
      return console.warn;
    case 'info':
      return console.info;
    default:
      return console.log;
  }
};

export const networkLog = (level: LogLevel, ...messages: string[]): void => {
  const consoleLogger = getConsoleLogger(level);

  consoleLogger(...messages);

  if ((window as AnyType).Rollbar) {
    (window as AnyType).Rollbar[level](messages.join('\n'));
  }
};
export const log = (level: LogLevel, ...logParams: AnyType[]): void => {
  const consoleLogger = getConsoleLogger(level);

  consoleLogger(...logParams);
};
export const inRange = (x:number, min:number, max:number): boolean => {
  return x >= min && x <= max;
};
export const generateScoreRanges = (min: number, max: number, noOfRanges: number): Array<MinMax> => {
  const difference: number = Math.abs((max - min) / noOfRanges);
  const ranges:Array<MinMax> = [];
  let buffer = min;
  for (let i = 0; i < noOfRanges; i += 1) {
    ranges.push({ min: parseFloat(buffer.toFixed(3)), max: parseFloat((buffer + difference).toFixed(3)) });
    buffer += difference;
  }
  return ranges;
};
export const getColourFromScore = (score: number | string | null, ranges: Array<MinMax>, theme = 'earthy'): string => {
  let color = colours.filterTrack;
  if (score === null) {
    return color;
  }
  let scoreFormatted = 0;
  if (typeof score === 'string') {
    scoreFormatted = parseFloat(score);
  } else {
    scoreFormatted = score;
  }
  for (let i = 0; i < ranges.length; i += 1) {
    if (inRange(scoreFormatted, ranges[i].min, ranges[i].max)) {
      const key = i + 1;
      const accessorValue = key === 10 ? `sentiment-${key}` : `sentiment-0${key}`;
      color = sentiments[theme][accessorValue].value;
      break;
    }
  }
  return color;
}
