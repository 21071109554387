/* eslint-disable @typescript-eslint/naming-convention */
const productionHosts = ['prod-aster-frontend.herokuapp.com', 'app.astertheplatform.com'];
const environment = productionHosts.includes(window.location.host) ? 'production' : 'staging';
const { REACT_APP_API_URL, REACT_APP_API_URL_PROD } = process.env;
const apiUrl = environment === 'production' ? REACT_APP_API_URL_PROD : REACT_APP_API_URL;
export const loginUrl = `${apiUrl}/v1/login`;
export const getUserDetailUrl = '';
export const groupingsUrl = `${apiUrl}/v1/groups`;
export const membersListUrl = `${apiUrl}/v1/members`;
export const addRemoveMembersUrl = `${apiUrl}/v1/groups/%group_id/members`;
export const trendsUrl = `${apiUrl}/v1/sentiments/trends`;
export const organisationTrendsUrl = `${apiUrl}/v1/sentiments/organisation-trends`;
export const lastDataSyncedInfoUrl = `${apiUrl}/v1/sentiments/last-updated`;
export const editMembersUrl = `${apiUrl}/v1/group_members`;
export const resetPasswordUrl = `${apiUrl}/v1/reset-password`;
export const recoverPasswordUrl = `${apiUrl}/v1/forgot-password`;
export const demographicsUrl = `${apiUrl}/v1/demographics`;
export const logoutUrl = '';
