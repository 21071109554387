/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { groupingsUrl } from 'definition/api';
import Lang from 'lang/en';
import { UpdateGroupFunctionParams, DateType } from '../types'
import { updateGroupDates } from '../utils';
export const updateGroupData = async (
  id: string,
  type: DateType,
  newDate: string,
) => {
  let data = {};
  if (type === DateType.startDate) {
    data = {
      start_date: newDate,
    }
  } else {
    data = {
      end_date: newDate,
    }
  }
  const response = await apiClient.put(`${groupingsUrl}/${id}`, { ...data });
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const updateGroup = createAsyncThunk(
  'updateGroup',
  async (data: UpdateGroupFunctionParams) => {
    const response = await updateGroupData(data.id, data.type, data.newDate);
    if (response) {
      const newNodeMap = updateGroupDates(data.id, data.type, data.newDate, data.nodeMap);
      return {
        nodeMap: newNodeMap,
      }
    }
    return {
      nodeMap: data.nodeMap,
    }
  },
);

export default updateGroup;
