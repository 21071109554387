import React from 'react';
import { Provider } from 'react-redux';
import { connectStoreToNetwork, store } from './appReducer';
import WebRoutes from './routes';

const App = () => {
  connectStoreToNetwork();
  return (
    <Provider store={store}>
      <WebRoutes />
    </Provider>
  );
};

export default App;
