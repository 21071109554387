import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyType } from '../../common/types';
import { log } from '../../utils/helpers';

const verify = createAsyncThunk(
  'verifySession',
  async (data, { getState }): Promise<void> => {
    const accountId = (getState() as AnyType).session.userData.id;
    const sessionUserId = localStorage.getItem('sessionUserId');

    if (String(accountId) !== sessionUserId) {
      window.location.reload();

      log('debug', `Session ID mismatch: ${sessionUserId} !== ${accountId}`);
    }
  },
);

export default verify;
