import { createAsyncThunk } from '@reduxjs/toolkit';
import { logoutUrl } from '../../definition/api';
import api from '../../utils/network/apiClient';
import setUserData from '../userData/setUserDataThunk';
import Lang from '../../lang/en';

const enableLogoutApiRequest = false;
const logout = createAsyncThunk(
  'logout',
  async (data, { dispatch }): Promise<void> => {
    localStorage.removeItem('sessionUserId');
    localStorage.removeItem('accessToken');
    if (enableLogoutApiRequest) {
      const response = await api.post(logoutUrl);

      await dispatch(setUserData(null));

      if (response.status >= 400) {
        throw new Error(response.data.message || Lang.invalidResponse);
      }
    }
  },
);

export default logout;
