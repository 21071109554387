import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { membersListUrl } from 'definition/api';
import Lang from 'lang/en';
import { createMemberMap } from '../utils';
import { GetMembersFunctionParams } from '../types';

export const getMembersData = async () => {
  const response = await apiClient.get(membersListUrl);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const getMembers = createAsyncThunk(
  'members',
  async (data: GetMembersFunctionParams) => {
    const response = await getMembersData();
    const memberMap = createMemberMap(response, data.groups);
    return {
      members: response,
      memberMap,
    }
  },
);

export default getMembers;
