import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserData } from '../../admin/user/types';
import { log } from '../../utils/helpers';

const setUserData = createAsyncThunk(
  'setUserData',
  async (data: UserData | null, { dispatch }) => {
    if (!data) {
      localStorage.removeItem('sessionUserId');
      localStorage.removeItem('userData');
      return;
    }

    if (!data.id) {
      log('warning', `Can't set session ID: ${data.id} value provided`);
    }

    localStorage.setItem('sessionUserId', String(data.id));
    if (data.token) {
      localStorage.setItem('accessToken', String(data.token));
    }
    localStorage.removeItem('userData');
    localStorage.setItem('userData', JSON.stringify(data));
    return data;
  },
);

export default setUserData;
