/* eslint-disable func-names */
import {
  GroupingI, PathMap, AnyType, MemberEntry, MemberMap,
} from 'common/types';
import traverse from 'traverse';
import { DateType, Demographic, EditGroupMemberDates } from './types';
interface GroupAndMemberCount {
  members: number;
  groups: number;
}
export const preProcessResponse = (response : GroupingI): GroupingI => {
  const newObj: GroupingI = {
    id: '',
    name: '',
    value: 1,
    children: [],
    isPlaceholder: false,
  };
  if (response?.children?.length === 0) {
    newObj.id = response.id.toString();
    newObj.name = response.name;
    newObj.children?.push({
      id: 'placeholderId',
      name: 'Your first group',
      value: 1,
      children: [],
      isPlaceholder: true,
    })
    return newObj;
  } else {
    const newData: GroupingI = JSON.parse(JSON.stringify(response));
    traverse(newData).forEach(function (node) {
      if (this.notRoot && node?.id) {
        const newNode = JSON.parse(JSON.stringify(node));
        newNode.id = node.member_id ? node.id : node.id.toString();
        newNode.value = 1;
        if (newNode.group_members?.length > 0) {
          newNode.children?.push({
            id: `${newNode.id}_members`,
            name: 'members',
            count: newNode.group_members?.length || 0,
            isMemberPool: true,
          });
        }
        this.update(newNode);
      } else if (this.isRoot && node.id) {
        const newRoot = JSON.parse(JSON.stringify(node));
        newRoot.id = response.id.toString();
        newRoot.value = 1
      }
    });
    return newData;
  }
};

export const addNewGroupProcessing = (data: GroupingI, zoomedId: string | null | undefined, currentPath: string[], currentDepth: number): GroupingI => {
  if (currentDepth === 0) {
    const newData: GroupingI = {
      id: data.id,
      name: data.name,
      value: 1,
      children: [],
      isPlaceholder: data.isPlaceholder,
    }
    data.children?.forEach((child) => {
      newData.children?.push(child)
    });
    const newObj: GroupingI = {
      id: `new_group_${data.id}_${data.children?.length}_${new Date().getTime().toString()}`,
      name: 'New group',
      value: 1,
      children: [],
      isPlaceholder: true,
    };
    newData.children?.push(newObj)
    return newData;
  }
  const newData: GroupingI = JSON.parse(JSON.stringify(data));
  traverse(newData).forEach(function (node) {
    if (node?.id === zoomedId) {
      const newNode: GroupingI = JSON.parse(JSON.stringify(node));
      const newObj: GroupingI = {
        id: `new_group_${node.id}_${node.children?.length}_${new Date().getTime().toString()}`,
        name: 'New group',
        value: 1,
        children: [],
        isPlaceholder: true,
      };
      newNode.children?.push(newObj);
      this.update(newNode, true);
    }
  });
  return newData;
};

export const buildPathMap = (data: AnyType, siblings:AnyType[] = [], traversedDepth = 0, currentPath: Array<string> = [], parent: string | null = null): PathMap => {
  const nodeMap:PathMap = {};
  let subNodeMap: PathMap = {};
  if (traversedDepth === 0) {
    nodeMap[data.id] = {
      id: data.id,
      name: data.name,
      value: data.value,
      depth: traversedDepth,
      isPlaceholder: data.isPlaceholder,
      path: [data.id],
      parent,
      siblings: [],
      startDate: data?.start_date || null,
      endDate: data?.end_date || null,
      children: data.children.map((child: AnyType) => (child.id)),
    }
    if (data.children) {
      data.children.forEach((child: AnyType) => {
        const childMap = buildPathMap(child, data.children, traversedDepth + 1, [data.id], data.id);
        subNodeMap = { ...subNodeMap, ...childMap };
      })
    }
  } else if (data.children) {
    const siblingsOfNode = siblings.filter((child) => (child.id !== data.id))
    const siblingsOfPath: AnyType[] = [];
    siblingsOfNode.forEach((child) => {
      if (child.id.indexOf('members') === -1) {
        siblingsOfPath.push({
          id: child.id,
          name: child.name,
          value: child.value,
          depth: traversedDepth,
          isPlaceholder: child.isPlaceholder,
          path: [...currentPath, child.id],
          parent,
          startDate: child?.start_date || null,
          endDate: child?.end_date || null,
          children: data.children.map((childElement: AnyType) => (childElement.id)),
        })
      }
    });
    nodeMap[data.id] = {
      id: data.id,
      name: data.name,
      value: data.value,
      depth: traversedDepth,
      isPlaceholder: data.isPlaceholder,
      siblings: [...siblingsOfPath],
      path: [...currentPath, data.id],
      parent,
      startDate: data?.start_date || null,
      endDate: data?.end_date || null,
      children: data.children.map((childElement: AnyType) => (childElement.id)),
      members: data.group_members ? [...data.group_members] : [],
    }
    if (data.children) {
      data.children.forEach((child: AnyType) => {
        const childMap = buildPathMap(child, data.children, traversedDepth + 1, [...currentPath, data.id], data.id);
        subNodeMap = { ...subNodeMap, ...childMap };
      })
    }
  }
  return { ...nodeMap, ...subNodeMap };
}

export const addPlaceholderName = (id: string, name: string, data: GroupingI) => {
  const newData: GroupingI = JSON.parse(JSON.stringify(data))
  traverse(newData).forEach(function (node) {
    if (node?.id === id) {
      const newNode: GroupingI = JSON.parse(JSON.stringify(node));
      newNode.name = name;
      this.update(newNode, true)
    }
  });
  return newData;
};

export const addPersistedGroup = (placholderId: string, persistedId: string, name: string, oldGroupData: GroupingI) => {
  const newData: GroupingI = JSON.parse(JSON.stringify(oldGroupData))
  traverse(newData).forEach(function (node) {
    if (node?.id === placholderId) {
      const newNode: GroupingI = JSON.parse(JSON.stringify(node));
      newNode.id = persistedId;
      newNode.name = name;
      newNode.isPlaceholder = false;
      this.update(newNode, true)
    }
  });
  return newData;
};

export const deleteExistingGroup = (parentId: string, id: string, oldGroupData: GroupingI) => {
  const newData: GroupingI = JSON.parse(JSON.stringify(oldGroupData));
  traverse(newData).forEach(function (node) {
    if (node?.id === id) {
      this.remove(true)
    }
  });
  return newData;
};

export const renameExistingGroup = (id: string, name: string, oldGroupData: GroupingI) => {
  const newData: GroupingI = JSON.parse(JSON.stringify(oldGroupData));
  traverse(newData).forEach(function (node) {
    if (node?.id === id) {
      const newNode: GroupingI = JSON.parse(JSON.stringify(node));
      newNode.name = name;
      this.update(newNode, true)
    }
  });
  return newData;
};

export const updateNodeMap = (id: string, name: string, nodeMap: PathMap, parentId: string) => {
  const newNodeMap: PathMap = JSON.parse(JSON.stringify(nodeMap));
  const parent = newNodeMap[parentId];
  const newNode: AnyType = {
    id,
    name,
    value: 1,
    depth: parent.depth + 1,
    isPlaceholder: false,
    path: [...parent.path, id],
    parent: parentId,
    children: [],
    members: [],
    startDate: formatDate(new Date()),
    endDate: null,
  };
  const newNodeWithSiblings: AnyType = JSON.parse(JSON.stringify(newNode));
  newNodeWithSiblings.siblings = [];
  newNodeMap[parentId].children.forEach((child: AnyType) => {
    if (child.indexOf('members') === -1) {
      newNodeMap[child].siblings.push(newNode);
      const siblingNode: AnyType = JSON.parse(JSON.stringify(newNodeMap[child]));
      delete siblingNode.siblings;
      newNodeWithSiblings.siblings.push(siblingNode);
    }
  })
  newNodeMap[id] = newNodeWithSiblings;
  newNodeMap[parentId].children.push(id);
  return newNodeMap;
};

export const updateNodeMapOnRename = (id: string, name: string, nodeMap: PathMap) => {
  const newNodeMap: PathMap = JSON.parse(JSON.stringify(nodeMap));
  traverse(newNodeMap).forEach(function (node) {
    if (node?.id === id) {
      const newNode: GroupingI = JSON.parse(JSON.stringify(node));
      newNode.name = name;
      this.update(newNode);
    }
  });
  return newNodeMap;
};

export const updateNodeMapOnDelete = (id: string, nodeMap: PathMap) => {
  let parent: string | null = null;
  const newNodeMap: PathMap = JSON.parse(JSON.stringify(nodeMap));
  traverse(newNodeMap).forEach(function (node) {
    if (node?.id === id) {
      parent = node.parent;
      this.remove();
    }
  });
  if (parent) {
    const newChildren = newNodeMap[parent].children.filter((child: string) => child !== id);
    newNodeMap[parent].children = [...newChildren]
  }
  return newNodeMap;
}

export const doSiblingsHaveChildren = (id: string, nodeMap: PathMap): boolean => {
  let result = false;
  result = nodeMap[id].siblings.some((node: AnyType) => {
    return nodeMap[node.id].children.length > 0;
  })
  return result;
};

export const createMemberMap = (members: MemberEntry[], data: AnyType): MemberMap => {
  let memberMap: MemberMap = {};
  members.forEach((member: MemberEntry) => {
    memberMap[member.id] = member;
    memberMap[member.id].groups = [];
    memberMap[member.id].key = member.id;
  });
  if (Object.keys(data).length > 0) {
    memberMap = getGroupsForMembers(memberMap, '', data, 0);
  }
  return memberMap;
};

const getGroupsForMembers = (memberMap: MemberMap, currentPath: string, data: AnyType, currentDepth: number): MemberMap => {
  let newMemberMap: MemberMap = { ...memberMap };
  const newPath = currentDepth !== 0 ? `${currentPath}/${data.name}` : '';
  if (data?.group_members && data?.group_members.length > 0) {
    data.group_members.forEach((member: AnyType) => {
      if (newMemberMap[member.member_id]) {
        newMemberMap[member.member_id].groups.push(newPath);
      }
    })
  }
  if (data?.children && data.children.length > 0) {
    data.children.forEach((child: AnyType) => {
      newMemberMap = getGroupsForMembers(newMemberMap, newPath, child, currentDepth + 1);
    })
  }
  return newMemberMap;
}
export const updateNodeMapAfterAddingMembers = (groupId: string, membersAdded: number[], memberMap: MemberMap, nodeMap: PathMap, response: AnyType[] = []): PathMap => {
  const newNodeMap: PathMap = JSON.parse(JSON.stringify(nodeMap));
  if (!newNodeMap[groupId].members) {
    newNodeMap[groupId].members = []
  }
  response.forEach((memberResponse: AnyType) => {
    const newMemberObject = { ...memberResponse };
    newMemberObject.member = memberMap[memberResponse.member_id];
    newNodeMap[groupId].members.push(newMemberObject);
  })
  return newNodeMap;
}

export const updateNodeMapAfterRemovingMembers = (groupId: string, membersRemoved: number[], nodeMap: PathMap): PathMap => {
  const newNodeMap: PathMap = JSON.parse(JSON.stringify(nodeMap));
  const updatedMembers = newNodeMap[groupId].members.filter((member: MemberEntry) => (!membersRemoved.includes(member.id)))
  newNodeMap[groupId].members = [...updatedMembers];
  if (newNodeMap[groupId].members.length === 0) {
    const childMemberId = `${groupId}_members`
    const newChildren = newNodeMap[groupId].children.filter((childId: string) => (childId !== childMemberId))
    newNodeMap[groupId].children = [...newChildren];
  }
  return newNodeMap;
}

export const updateDataWhenMembersAreAdded = (groupId: string, nodeMap: PathMap, data: GroupingI): GroupingI => {
  const newData: GroupingI = JSON.parse(JSON.stringify(data));
  traverse(newData).forEach(function (node) {
    if (node?.id === groupId && node?.age === undefined && node?.gender === undefined) {
      const newNode = JSON.parse(JSON.stringify(node));
      const memberChildName = `${newNode.id}_members`;
      const memberExists = newNode?.children.some((child: AnyType) => (child.id === memberChildName))
      if (!memberExists) {
        newNode.children?.push({
          id: `${newNode.id}_members`,
          name: 'members',
          count: nodeMap[groupId]?.members?.length || 0,
          isMemberPool: true,
          value: 1,
        })
      }
      newNode.members = [...nodeMap[groupId].members] || [];
      this.update(newNode, true)
    }
  });
  traverse(newData).forEach(function (node) {
    if (node?.id === groupId) {
      const newNode = JSON.parse(JSON.stringify(node));
      newNode.group_members = nodeMap[groupId].members;
      this.update(newNode, true)
    }
  });
  return newData;
}

export const updateDataWhenMembersAreRemoved = (groupId: string, nodeMap: PathMap, data: GroupingI): GroupingI => {
  const removeNode = nodeMap[groupId].members.length === 0;
  const newData: GroupingI = JSON.parse(JSON.stringify(data));
  const memberChildId = `${groupId}_members`;
  if (removeNode) {
    traverse(newData).forEach(function (node) {
      if (node?.id === memberChildId) {
        this.remove();
      }
    });
  } else {
    traverse(newData).forEach(function (node) {
      if (node?.id === memberChildId) {
        const newNode = JSON.parse(JSON.stringify(node));
        newNode.count = nodeMap[groupId]?.members?.length || 0;
        this.update(newNode, true)
      }
    });
  }
  traverse(newData).forEach(function (node) {
    if (node?.id === groupId) {
      const newNode = JSON.parse(JSON.stringify(node));
      newNode.group_members = nodeMap[groupId].members;
      this.update(newNode, true)
    }
  });
  return newData;
}

export const clearChildrenOfZoomedId = (zoomedId: string, name: string, data: AnyType) => {
  const newData = traverse(data).map(function (node) {
    const newNode: GroupingI = JSON.parse(JSON.stringify(node));
    if (node?.id === zoomedId && node?.name === name) {
      const newChildren: GroupingI[] = []
      newNode?.children?.forEach((child: AnyType) => {
        if (!child.isMemberPool) {
          const newChild = {
            id: child.id,
            name: child.name,
            value: 1,
          }
          newChildren.push(newChild)
        } else if (child.isMemberPool) {
          const newChild = {
            count: child.count,
            id: child.id,
            isMemberPool: true,
            name: 'members',
            value: 1,
          }
          newChildren.push(newChild)
        }
      })
      newNode.children = [...newChildren];
      this.update(newNode);
    }
    return newNode;
  });
  return newData;
};
export const isExistingChild = (id: string, parentId: string, data: GroupingI): boolean => {
  let result = false;
  traverse(data).forEach(function (node) {
    if (node?.id === parentId && !node?.member_id && node?.age === undefined && node?.gender === undefined) {
      node?.children.forEach((child: GroupingI) => {
        if (child.id === id) {
          result = true;
        }
      })
      this.update(node, true)
    }
  })
  return result;
}

export const updateGroupDates = (id: string, type: DateType, newDate: string, nodeMap: PathMap): PathMap => {
  const newNodeMap: PathMap = JSON.parse(JSON.stringify(nodeMap));
  if (type === DateType.startDate) {
    newNodeMap[id].startDate = newDate;
  } else if (type === DateType.endDate) {
    newNodeMap[id].endDate = newDate;
  }
  return newNodeMap;
};

export const dateInRange = (startDate: Date, endDate: Date | null): boolean => {
  const today = new Date();
  if (!endDate && startDate > today) {
    return false;
  } else if (endDate && !(today >= startDate && today <= endDate)) {
    return false;
  }
  return true;
};

export const formatDate = (date: Date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) { month = `0${month}`; }
  if (day.length < 2) { day = `0${day}`; }

  return [year, month, day].join('-');
};

export const countGroupsAndMembers = (id: string, data: AnyType): GroupAndMemberCount => {
  let groups = -1;
  let members = 0;
  let selectedNode: PathMap = {};
  traverse(data).forEach((node) => {
    if (node?.id === id && !node?.member_id && node?.age === undefined && node?.gender === undefined) {
      selectedNode = node;
    }
  })
  traverse(selectedNode).forEach((node) => {
    if (node?.id && !node?.member_id && node?.group_members) {
      members += node.group_members.length;
    }
    if (node?.id && !node?.member_id && node?.age === undefined && node?.gender === undefined && !node?.id.includes('members')) {
      groups += 1;
    }
  })
  return { members, groups };
};

export const getGroupMemberIdsToDelete = (groupId: string, membersToRemove: number[], nodeMap: PathMap): number[] => {
  const idsToDelete: number[] = [];
  const nodeMapMembers = nodeMap[groupId].members;
  nodeMapMembers.forEach((member: AnyType) => {
    if (membersToRemove.includes(member.member_id)) {
      idsToDelete.push(member.id);
    }
  });
  return idsToDelete;
};
export const getGroupMemberId = (memberId: number, groupId: string, nodeMap: PathMap): number => {
  let groupMemberId = -1;
  const membersOfGroup = nodeMap[groupId].members;
  membersOfGroup.forEach((member: AnyType) => {
    if (member.member_id === memberId) {
      groupMemberId = member.id;
    }
  })
  return groupMemberId;
};
export const getDisplayDate = (date: string): string => {
  const dateValue = new Date(date);
  const dateArr = dateValue.toDateString().split(' ');
  if (dateArr.length > 3) {
    const formattedDateString = `${dateArr[2]} ${dateArr[1]} ${dateArr[3]}`;
    return formattedDateString;
  }
  return '';
}
export const updateNodemapOnEditMember = (groupMemberId: number, groupId: string, nodeMap: PathMap, memberDates: EditGroupMemberDates) : PathMap => {
  const newNodeMap: PathMap = JSON.parse(JSON.stringify(nodeMap));
  const updatedMembers: MemberEntry[] = [];
  if (newNodeMap[groupId]?.members) {
    newNodeMap[groupId]?.members.forEach((member: MemberEntry) => {
      if (member.id === groupMemberId) {
        const updatedMember: MemberEntry = { ...member, ...memberDates };
        updatedMembers.push(updatedMember)
      } else {
        updatedMembers.push(member)
      }
    })
  }
  newNodeMap[groupId].members = [...updatedMembers];
  return newNodeMap;
}
export const getNextDay = (date: Date | string): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate;
};
export const getPreviousDay = (date: Date | string | null): Date | undefined => {
  if (date) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
  }
  return undefined;
};
export const formatDateForApi = (date: Date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) { month = `0${month}`; }
  if (day.length < 2) { day = `0${day}`; }

  return [year, month, day].join('-');
}
export const formatDateForDemographicsApi = (date: Date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) { month = `0${month}`; }
  if (day.length < 2) { day = `0${day}`; }

  return [day, month, year].join('/');
}
export const sortByNameAscending = (member1: MemberEntry, member2: MemberEntry): number => {
  if (member1.name.toLowerCase() < member2.name.toLowerCase()) {
    return -1;
  }
  return 1;
};
export const sortByNameDescending = (member1: MemberEntry, member2: MemberEntry): number => {
  if (member1.name.toLowerCase() > member2.name.toLowerCase()) {
    return -1;
  }
  return 1;
};
export const updateMemberMaponEditMemberDemographic = (id: number, type: Demographic, value: string, memberMap: MemberMap): MemberMap => {
  if (!memberMap[id]) {
    return memberMap;
  }
  const newMemberMap: PathMap = JSON.parse(JSON.stringify(memberMap));
  newMemberMap[id][type] = value;
  return newMemberMap;
}
export const genders: string[] = ['Male', 'Female', 'Other'];
export const ethnicity: string[] = ['White', 'Asian', 'Black', 'Multiple Ethnic Groups', 'Other'];
