import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResetData } from '../../admin/user/types';
import { log } from '../../utils/helpers';

const setResetData = createAsyncThunk(
  'setResetData',
  async (data: ResetData | null, { dispatch }) => {
    if (!data) {
      localStorage.removeItem('sessionUserId');
      localStorage.removeItem('userData');
      return;
    }

    if (!data.resetToken) {
      log('warning', "Can't set reset token");
    }
    return data;
  },
);

export default setResetData;
