import {
  AnyType, MinMax, PathMap, Timeframe,
} from 'common/types';
import { TrendsMap, TimelineConfig } from 'components/Trends/utils';
export interface DashboardState {
  breadCrumbs: AnyType [],
  score: number | string,
  zoomedId: string | null | undefined,
  currentDepth: number,
  currentPath: string[],
  minScore: number,
  maxScore: number,
  ranges: Array<MinMax>,
  nodeMap: PathMap,
  loading: LoadingStates,
  data: AnyType,
  noOfTeams: number,
  filteredMinScore: number,
  filteredMaxScore: number,
  viewTrends: boolean,
  trendsLoading: LoadingStates,
  trendsMap: TrendsMap,
  trendsMinScore: number,
  trendsMaxScore: number,
  trendsCompareList: string[],
  allowTrendsCompare: boolean,
  timelineConfig: TimelineConfig,
  yearAndMonthList: string[],
  showDateFilter: boolean,
  lastDataSyncedInfo: string,
  timeframeSelected: Timeframe,
  trendsType: TrendsType,
  demographicsMap: DemographicsMap,
  demographicsLoading: LoadingStates,
  demographicsHiddenList: string[],
}
export interface DashboardValues {
  zoomedId: string | null | undefined,
  currentDepth: number,
  currentPath: string[],
  breadCrumbs? :AnyType[],
  score?: number
}
export enum LoadingStates {
  pending = 'pending',
  loaded = 'loaded',
  error = 'error',
}
export interface GetTrendsFunctionParams {
  theme: string;
}
export enum TrendsType {
  overall = 'overall',
  people = 'people',
}
export enum DemographicFilterType {
  age = 'age',
  gender = 'gender',
  ethnicity = 'ethnicity',
  tenure = 'tenure',
}
export interface GetDemographicsFunctionParams {
  theme: string;
  groupId: string;
}
export type DemographicsMap = {
  age?: DemographicsTrendsData;
  gender?: DemographicsTrendsData;
  ethnicity?: DemographicsTrendsData;
  tenure?: DemographicsTrendsData;
};
export interface DemographicsTrendsData {
  maxScore: number;
  minScore: number;
  trendsMap: TrendsMap;
  yearAndMonthList: string[];
}
