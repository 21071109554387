import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserDetailUrl } from '../../definition/api';
import api from '../../utils/network/apiClient';
import setUserData from '../userData/setUserDataThunk';
import Lang from '../../lang/en';

const check = createAsyncThunk(
  'checkSession',
  async (data, { dispatch }): Promise<void> => {
    const userId = localStorage.getItem('sessionUserId');
    if (!userId) {
      return;
    }
    const sessionResponse = await api(`${getUserDetailUrl}/${userId}`);
    if (sessionResponse?.status >= 400) {
      dispatch(setUserData(null));
      throw new Error(sessionResponse?.data.message || Lang.invalidResponse);
    }

    await dispatch(setUserData(sessionResponse?.data.data));
  },
);

export default check;
