import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../../utils/network/apiClient';
import { UserI } from '../../settings/account/types';
import { getUserDetailUrl } from '../../../definition/api';
import Lang from '../../../lang/en';

export const fetchUserDetailsData = async (
  id: number | undefined,
): Promise<UserI> => {
  const response = await apiClient(`${getUserDetailUrl}/${id}`);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const fetchUserDetails = createAsyncThunk(
  'users',
  async (id: number | undefined) => {
    const response = await fetchUserDetailsData(id);
    return response;
  },
);

export default fetchUserDetails;
