/* eslint-disable @typescript-eslint/naming-convention */
interface Colours {
  [key: string]: string;
}
interface ColorType{
  value: string,
  type: string,
}
interface SentimentThemeObj{
  [key: string]: ColorType
}
interface SentimentTheme {
  [key: string]: SentimentThemeObj
}
interface SentimentGradient {
  [key: string]: string
}
export const colours: Colours = {
  white: '#FFFFFF',
  groupGrey: '#F8F8F8',
  black: '#000000',
  negativeDefault: '#FF4B4B',
  negativeDark: '#CE2626',
  neutralDefault: '#FFDF8D',
  neutralDark: '#CC9300',
  positiveDefault: '#D2FE86',
  positiveDark: '#73AB18',
  gray75: 'rgba(0, 0, 0, 0.75)',
  gray60: 'rgba(0, 0, 0, 0.6)',
  gray40: 'rgba(0, 0, 0, 0.4)',
  gray15: 'rgba(0, 0, 0, 0.15)',
  gray05: 'rgba(0, 0, 0, 0.05)',
  gray00: '#1D1D1B',
  transparentWhite: '#ffffff00',
  defaultBackground: 'linear-gradient(180deg, rgba(237, 237, 237, 0) 0%, #D3D3D3 100%)',
  filterTrack: '#f2f2f2',
  transparent: 'transparent',
};
export const sentiments: SentimentTheme = {
  earthy:
  {
    'sentiment-10': {
      value: '#CEF5A3',
      type: 'color',
    },
    'sentiment-09': {
      value: '#D3EDA3',
      type: 'color',
    },
    'sentiment-08': {
      value: '#D8E5A3',
      type: 'color',
    },
    'sentiment-07': {
      value: '#DBDFA3',
      type: 'color',
    },
    'sentiment-06': {
      value: '#DFD9A3',
      type: 'color',
    },
    'sentiment-05': {
      value: '#E3D2A3',
      type: 'color',
    },
    'sentiment-04': {
      value: '#E7CBA3',
      type: 'color',
    },
    'sentiment-03': {
      value: '#E9C7A3',
      type: 'color',
    },
    'sentiment-02': {
      value: '#EEC0A3',
      type: 'color',
    },
    'sentiment-01': {
      value: '#F5B5A3',
      type: 'color',
    },
  },
}
export const sentimentGradients: SentimentGradient = {
  earthy: 'linear-gradient(90deg, #F5B5A3 0%, #EEC0A3 10%, #E9C7A3 20%, #E7CBA3 30%, #E3D2A3 40%, #DFD9A3 50%, #DBDFA3 60%, #D8E5A3 70%, #D3EDA3 80%, #CEF5A3 90%, #C8FFA3 100%)',
};

export const timelineColours : string[] = ['#E2A0FF', '#036666', '#FFCF56', '#1E6091', '#ED4D6E', '#F78154', '#1E3F20', '#2B9348', '#A5BE00', '#FB6107'];
