/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { log } from 'utils/helpers';
import { groupingsUrl } from 'definition/api';
import { Mixpanel } from 'utils/mixpanel';
import Lang from 'lang/en';
import { AddGroupResponseType, AddGroupRequestParams, AddGroupFunctionParams } from '../types'
import { addPersistedGroup, updateNodeMap } from '../utils';
export const addGroupData = async (
  params: AddGroupFunctionParams,
): Promise<AddGroupResponseType> => {
  const data: AddGroupRequestParams = { name: params.name }
  if (params.parent_id) {
    data.parent_id = params.parent_id;
  }
  const response = await apiClient.post(groupingsUrl, data);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};

const addGroup = createAsyncThunk(
  'addGroup',
  async (data: AddGroupFunctionParams) => {
    const response = await addGroupData(data);
    const newData = addPersistedGroup(data.id, response.id.toString(), response.name, data.oldGroupData);
    const newNodeMap = updateNodeMap(response.id.toString(), response.name, data.nodeMap, data.parentId);
    try {
      const userInfo = JSON.parse(localStorage.getItem('userData') || '{}');
      const orgId = userInfo.id || ''
      const mixpanelProps = {
        $distinct_id: orgId.toString(),
        $name: userInfo.name || '',
        $email: userInfo.email || '',
        $organisation: userInfo.name || '',
        $group_name: data.name,
        $group_type: data.parent_id ? 'Child' : 'Parent',
      }
      Mixpanel.identify(orgId.toString());
      Mixpanel.track('Create Group', mixpanelProps);
    } catch (err) {
      log('error', `Mixpanel tracking error: ${err}`);
    }
    return { data: newData, nodeMap: newNodeMap };
  },
);

export default addGroup;
