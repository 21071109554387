/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'utils/network/apiClient';
import { demographicsUrl } from 'definition/api';
import Lang from 'lang/en';
import { AnyType } from 'common/types';
import { createGroupDemographicsMap } from 'components/Trends/utils';
import { GetDemographicsFunctionParams } from '../types';
export const getDemographicsInfo = async (groupId: string): Promise<AnyType> => {
  const url = groupId ? `${demographicsUrl}/${groupId}` : demographicsUrl;
  const response = await apiClient.get(url);
  if (response.status >= 400) {
    throw new Error(response.data.message || Lang.invalidResponse);
  }
  return response.data?.data || {};
};
const getDemographics = createAsyncThunk(
  'getDemographics',
  async (data: GetDemographicsFunctionParams) => {
    const response = await getDemographicsInfo(data.groupId);
    const demographicsMap = createGroupDemographicsMap(response, data.theme);
    return demographicsMap;
  },
);

export default getDemographics;
